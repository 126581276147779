import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import FlexRow from "./FlexRow";
import IconStepperBox from "./IconStepperBox";

const CustomBox = styled(Box)(({ theme, yellow, purple, ...props }) => ({
  position: "relative",
  height: "4px",
  width: "100%",
  borderRadius: "3px",
  background: yellow ? "#FFF0D3" : purple ? "#FFF5FC" : "#02403C",
  "& .MuiBox-root": {
    position: "absolute",
    width: yellow ? "50%" : "100%",
    height: "100%",
    background: yellow ? "#FF9B43" : "transparent",
  },
}));

const IconStepperContainer = (props) => {
  return (
    <FlexRow
      alignItems="center"
      justifyContent="strech"
      width="100%"
      gap="20px"
    >
      <IconStepperBox {...props}>{props.children}</IconStepperBox>
      <CustomBox {...props}>
        <Box />
      </CustomBox>
    </FlexRow>
  );
};

export default IconStepperContainer;

import { Grid, TextField, Typography } from "@mui/material";
import StyledDottedDivider from "../../components/common/divider/StyledDottedDivider";
import {
  BorderCard,
  FlexColumn,
  FlexRow,
  MaxWidthContainer,
  PageContainer,
} from "../../components/shared/container/Index";
import StyledButton from "../../components/common/button/StyledButton";
import StyledFooter from "../../components/common/navigation/StyledFooter";
import { styled } from "@mui/system";
import StyledSliderImage from "../../components/common/slider/StyledSliderImage";
import { useBlogs } from "../../context/BlogContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CustomTextField = styled(TextField)(({ purple, ...props }) => ({
  background: "#FFFFFF",
  color: purple ? "#4D033F" : "#02403C",
  "& .MuiOutlinedInput-root": {
    color: purple ? "#4D033F" : "#02403C",
    borderRadius: "10px",
    "& fieldset": {
      color: purple ? "#4D033F" : "#02403C",
      borderColor: purple ? "#4D033F" : "#02403C",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      color: purple ? "#4D033F" : "#02403C",
      borderColor: purple ? "#4D033F" : "#02403C",
    },
    "&.Mui-focused fieldset": {
      color: purple ? "#4D033F" : "#02403C",
      borderColor: purple ? "#4D033F" : "#02403C",
    },
  },
  "& .MuiInputLabel-root": {
    color: purple ? "#4D033F" : "#02403C", // Aquí se especifica el color del label
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: purple ? "#4D033F" : "#02403C", // Aquí se especifica el color del label cuando está enfocado
  },
}));

const Resources = () => {
  const { blogs } = useBlogs();
  const navigate = useNavigate();

  const handleCardClick = (slug) => {
    navigate(`/blogs/${slug}`);
    window.scrollTo(0, 0);
  };

  const [searchText, setSearchText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Extraer categorías únicas
  const categories = [...new Set(blogs.flatMap((blog) => blog.categories))];

  // Manejar el cambio en el campo de texto
  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toLowerCase());
  };

  // Manejar el clic en una categoría
  const handleCategoryClick = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
  };

  // Filtrar blogs
  const filteredBlogs = blogs.filter((blog) => {
    const matchesTitle = blog.title.toLowerCase().includes(searchText);
    const matchesCategory =
      !selectedCategory || blog.categories.includes(selectedCategory);
    return matchesTitle && matchesCategory;
  });

  return (
    <PageContainer>
      <MaxWidthContainer>
        <FlexColumn gap="24px" sx={{ margin: "60px 0px 0px 0px" }}>
          <Typography variant="h1">Recursos de ética algoritmica</Typography>
          <Typography variant="body1">
            Naveguemos, descubramos y fomentemos la inteligencia artificial
            responsable
          </Typography>
          <CustomTextField
            label="Buscar"
            variant="outlined"
            fullWidth
            value={searchText}
            onChange={handleSearchChange}
          />
          <FlexRow padding="32px 0px" gap="8px" sx={{ flexWrap: "wrap" }}>
            {categories.map((category) => (
              <StyledButton
                key={category}
                variant={
                  category === selectedCategory ? "contained" : "outlined"
                }
                secondary={category === selectedCategory ? false : true}
                onClick={() => handleCategoryClick(category)}
              >
                <Typography
                  variant="body1"
                  sx={{
                    textWrap: "nowrap",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  {category}
                </Typography>
              </StyledButton>
            ))}
          </FlexRow>
        </FlexColumn>
      </MaxWidthContainer>
      <StyledDottedDivider purple />
      <MaxWidthContainer>
        <Grid
          container
          spacing={3}
          columns={{ xs: 4, sm: 4, md: 12, xl: 12, alignContent: "flex-start" }}
        >
          {filteredBlogs.map((blog) => (
            <Grid item xs={4} sm={4} md={6} xl={4}>
              <BorderCard sx={{ margin: "16px" }}>
                <FlexColumn
                  gap="20px"
                  alignItems="flex-start"
                  justifyContent="center"
                  padding="20px 28px"
                >
                  <StyledSliderImage src={blog.image} alt={blog.slug} />
                  <Typography
                    variant="h5"
                    sx={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitLineClamp: 1,
                    }}
                  >
                    {blog.title}
                  </Typography>
                  <FlexRow gap="8px" width="100%" justifyContent="stretch">
                    {blog.categories.slice(0, 3).map((category) => (
                      <StyledButton
                        variant="outlined"
                        secondary="true"
                        sx={{ width: "33%" }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            textWrap: "nowrap",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {category}
                        </Typography>
                      </StyledButton>
                    ))}
                  </FlexRow>
                  <Typography
                    variant="body1"
                    sx={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitLineClamp: 2,
                      minHeight: "54px",
                    }}
                  >
                    {blog.summary}
                  </Typography>
                  <StyledButton
                    variant="contained"
                    onClick={() => handleCardClick(blog.slug)}
                  >
                    Ver más
                  </StyledButton>
                </FlexColumn>
              </BorderCard>
            </Grid>
          ))}
        </Grid>
      </MaxWidthContainer>
      <StyledFooter />
    </PageContainer>
  );
};

export default Resources;

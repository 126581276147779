// src/styles/pdfStyles.js
import { StyleSheet, Font } from "@react-pdf/renderer";
import AtypDisplay from "../../../fonts/atypDisplay/AtypDisplay-Regular.ttf";
import AtypDisplayBold from "../../../fonts/atypDisplay/AtypDisplay-Bold.ttf";
import AtypDisplayLight from "../../../fonts/atypDisplay/AtypDisplay-Light.ttf";
import AtypDisplayMedium from "../../../fonts/atypDisplay/AtypDisplay-Medium.ttf";
import AtypDisplaySemibold from "../../../fonts/atypDisplay/AtypDisplay-Semibold.ttf";
import AtypDisplayThin from "../../../fonts/atypDisplay/AtypDisplay-Thin.ttf";

Font.register({
  family: "Atyp Display",
  fonts: [
    {
      src: AtypDisplay,
      format: "truetype",
    },
    {
      src: AtypDisplayBold,
      format: "truetype",
      fontWeight: "bold",
    },
    {
      src: AtypDisplayLight,
      format: "truetype",
      fontWeight: "light",
    },
    {
      src: AtypDisplayMedium,
      format: "truetype",
      fontWeight: "medium",
    },
    {
      src: AtypDisplaySemibold,
      format: "truetype",
      fontWeight: "semibold",
    },
    {
      src: AtypDisplayThin,
      format: "truetype",
      fontWeight: "thin",
    },
  ],
});

// Estilos para el PDF
const styles = StyleSheet.create({
  page: {
    padding: "32px 40px",
  },
  image: {
    marginLeft: "auto",
    width: "102px",
    height: "23px",
  },
  boxRow: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    marginBottom: "8px",
    width: "100%",
  },
  boxRowWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "8px",
  },
  boxColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: "8px",
  },
  title: {
    color: "#02403C",
    fontFamily: "Atyp Display",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "normal",
    marginBottom: "8px",
  },
  subtitle: {
    color: "#02403C",
    fontFamily: "Atyp Display",
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "normal",
    marginBottom: "8px",
  },
  text: {
    color: "#000000",
    fontFamily: "Atyp Display",
    textAlign: "justify",
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "8px",
  },
  bulletPoint: {
    color: "#02403C",
    fontFamily: "Atyp Display",
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "normal",
    marginBottom: "8px",
  },
  bulletText: {
    color: "#000000",
    fontFamily: "Atyp Display",
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "8px",
    paddingLeft: "16px",
  },
  graphtext: {
    color: "#02403C",
    fontFamily: "Atyp Display",
    fontStyle: "italic",
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "8px",
  },
  greentext: {
    color: "#02403C",
    fontFamily: "Atyp Display",
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "8px",
  },
  graphBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    paddingTop: "12px",
  },
  graphBoxHalfWidth: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "8px",
    height: "200px",
    width: "33.3%",
  },
  graphBoxDinamic: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "8px",
    marginBottom: "8px",
  },
  graph: {
    width: "auto",
    height: "200px",
  },
  metricBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifycontent: "center",
    padding: "6px 4px 4px 4px",
  },
  metricText: {
    color: "#02403C",
    fontFamily: "Atyp Display",
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "normal",
  },
});

export default styles;

import { Grid, styled, TextField, Typography } from "@mui/material";
import StyledFooter from "../../components/common/navigation/StyledFooter";
import PageContainer from "../../components/shared/container/PageContainer";
import BorderCard from "../../components/shared/container/BorderCard";
import FlexColumn from "../../components/shared/container/FlexColumn";
import StyledButton from "../../components/common/button/StyledButton";
import MaxWidthContainer from "../../components/shared/container/MaxWidthContainer";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import FlexRow from "../../components/shared/container/FlexRow";
import IconCheck from "../../components/icons/IconCheck";

const CustomTextField = styled(TextField)(({ purple, ...props }) => ({
  color: "#02403C",
  "& .MuiOutlinedInput-root": {
    color: "#02403C",
    borderRadius: "10px",
    "& fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
    },
    "&.Mui-focused fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#02403C",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#02403C",
  },
}));

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [idUser, setIdUser] = useState("");
  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPassValid, setIsPassValid] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };
  const handleCodeChange = (event) => {
    const value = event.target.value;
    setCode(value);
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);

    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.{8,})/;

    if (!passwordRegex.test(value)) {
      setIsPassValid(false);
    } else {
      setIsPassValid(true);
    }
  };
  const handlePasswordConfirmChange = (event) => {
    const value = event.target.value;
    setPasswordConfirm(value);
    setPasswordsMatch(value === password);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateNewUser = async () => {
    setLoading(true);
    const body = {
      email: email,
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "is_email_used/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();
      if (data.is_email_used) {
        sendVerificationCode();
      } else {
        toast.error(`No existe un usuario con este correo.`);
      }
    } catch (error) {
      if (error.response && error.response.msg) {
        toast.error(`Error: ${error.response.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al validar el correo.");
      }
    }
    setLoading(false);
  };

  const sendVerificationCode = async () => {
    setLoading(true);
    const body = {
      email: email,
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "send_reset_code/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      if (response.ok) {
        setStep(1);
        const data = await response.json();
        toast.success(data.msg);
      } else {
        toast.error(`Error: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.msg) {
        toast.error(`Error: ${error.response.msg}`);
      } else {
        toast.error(
          "Error: Ocurrió un error al generar el código de verificación."
        );
      }
    }
    setLoading(false);
  };

  const verifyCode = async () => {
    setLoading(true);
    const body = {
      code: code,
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "verify_password_reset_code/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      if (response.ok) {
        setStep(2);
        const data = await response.json();
        toast.success(data.msg);
        setIdUser(data.id_user);
      } else {
        toast.error(`Error: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.msg) {
        toast.error(`Error: ${error.response.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al verificar el código.");
      }
    }
    setLoading(false);
  };

  const resetPassword = async () => {
    setLoading(true);
    const body = {
      id_user: idUser,
      new_password: password,
      email: email,
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "reset_password/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      if (response.ok) {
        setStep(3);
        const data = await response.json();
        toast.success(data.msg);
      } else {
        toast.error(`Error: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.msg) {
        toast.error(`Error: ${error.response.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al verificar el código.");
      }
    }
    setLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    validateNewUser();
  };

  return (
    <PageContainer>
      <MaxWidthContainer>
        <Grid
          container
          spacing={3}
          sx={{ marginTop: "72px", marginBottom: "72px" }}
          columns={{ xs: 4, sm: 12, xl: 12 }}
        >
          {step === 0 && (
            <Grid item xs={4} sm={6} xl={6}>
              <BorderCard shadow>
                <FlexColumn
                  padding="28px"
                  component="form"
                  gap="28px"
                  alignItems="flex-start"
                  onSubmit={handleSubmit}
                >
                  <FlexColumn gap="28px" alignItems="flex-start" width="100%">
                    <Typography variant="h5" color={"#02403C"}>
                      Introduce la dirección de correo electrónico registrada
                    </Typography>
                    <CustomTextField
                      label="Correo"
                      variant="outlined"
                      fullWidth
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      error={!isEmailValid}
                      helperText={
                        !isEmailValid && "Correo electrónico inválido"
                      }
                    />
                  </FlexColumn>
                  <FlexRow gap="32px">
                    <StyledButton variant="outlined" secondary>
                      <Typography
                        variant="h5"
                        sx={{
                          lineHeight: "100%",
                          marginBottom: "4px",
                          fontWeight: "500",
                          color: "#EBFDEA",
                        }}
                        onClick={() => handleNavigation("/login")}
                      >
                        Volver
                      </Typography>
                    </StyledButton>
                    <StyledButton
                      variant="contained"
                      type="submit"
                      disabled={email === "" || loading}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          lineHeight: "100%",
                          marginBottom: "4px",
                          fontWeight: "500",
                          color: "#EBFDEA",
                        }}
                        color={"#02403C"}
                      >
                        Continuar
                      </Typography>
                    </StyledButton>
                  </FlexRow>
                </FlexColumn>
              </BorderCard>
            </Grid>
          )}
          {step === 1 && (
            <Grid item xs={4} sm={6} xl={6}>
              <BorderCard shadow>
                <FlexColumn
                  padding="28px"
                  component="form"
                  gap="28px"
                  alignItems="flex-start"
                >
                  <FlexColumn gap="28px" alignItems="flex-start" width="100%">
                    <Typography variant="h5" color={"#02403C"}>
                      Código de verificación
                    </Typography>
                    <CustomTextField
                      label="Código de verificación"
                      variant="outlined"
                      fullWidth
                      value={code}
                      onChange={handleCodeChange}
                    />
                  </FlexColumn>
                  <FlexRow gap="32px">
                    <StyledButton variant="outlined" secondary>
                      <Typography
                        variant="h5"
                        sx={{
                          lineHeight: "100%",
                          marginBottom: "4px",
                          fontWeight: "500",
                          color: "#EBFDEA",
                        }}
                        onClick={() => setStep(0)}
                      >
                        Volver
                      </Typography>
                    </StyledButton>
                    <StyledButton
                      variant="contained"
                      disabled={code === "" || loading}
                      onClick={() => verifyCode()}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          lineHeight: "100%",
                          marginBottom: "4px",
                          fontWeight: "500",
                          color: "#EBFDEA",
                        }}
                      >
                        Verificar
                      </Typography>
                    </StyledButton>
                  </FlexRow>
                </FlexColumn>
              </BorderCard>
            </Grid>
          )}
          {step === 2 && (
            <Grid item xs={4} sm={6} xl={6}>
              <BorderCard shadow>
                <FlexColumn
                  padding="28px"
                  component="form"
                  gap="28px"
                  alignItems="flex-start"
                >
                  <FlexColumn gap="28px" alignItems="flex-start" width="100%">
                    <Typography variant="h5" color={"#02403C"}>
                      Crear nueva contraseña
                    </Typography>
                    <CustomTextField
                      label="Nueva contraseña"
                      variant="outlined"
                      fullWidth
                      type="password"
                      onChange={handlePasswordChange}
                      value={password}
                      error={!isPassValid}
                      helperText={
                        !isPassValid &&
                        "La contraseña debe tener al menos una mayúscula, un número, un carácter especial y mínimo de 8 caracteres."
                      }
                    />
                    <CustomTextField
                      label="Confirmar nueva contraseña"
                      variant="outlined"
                      fullWidth
                      type="password"
                      error={!passwordsMatch}
                      helperText={
                        !passwordsMatch && "Las contraseñas no coinciden"
                      }
                      onChange={handlePasswordConfirmChange}
                      value={passwordConfirm}
                    />
                  </FlexColumn>

                  <StyledButton
                    variant="contained"
                    disabled={
                      password === "" || passwordConfirm === "" || loading
                    }
                    onClick={() => resetPassword()}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        lineHeight: "100%",
                        marginBottom: "4px",
                        fontWeight: "500",
                        color: "#EBFDEA",
                      }}
                    >
                      Guardar
                    </Typography>
                  </StyledButton>
                </FlexColumn>
              </BorderCard>
            </Grid>
          )}
          {step === 3 && (
            <Grid item xs={4} sm={6} xl={6}>
              <BorderCard shadow>
                <FlexColumn
                  padding="28px"
                  gap="28px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <IconCheck sx={{ width: "120px", height: "120px" }} />
                  <Typography
                    variant="h5"
                    sx={{
                      lineHeight: "100%",
                      marginBottom: "4px",
                      fontWeight: "600",
                      color: "#2D2D36",
                    }}
                  >
                    Contraseña actualizada correctamente
                  </Typography>
                </FlexColumn>
              </BorderCard>
            </Grid>
          )}
        </Grid>
      </MaxWidthContainer>
      <StyledFooter />
    </PageContainer>
  );
};

export default ForgotPassword;

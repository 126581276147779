import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PageContainerDashboard,
  FlexColumn,
  FlexRow,
  MaxWidthContainer,
  BorderCard,
} from "../../components/shared/container/Index";
import {
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import StyledDivider from "../../components/common/divider/StyledDivider";
import StyledButton from "../../components/common/button/StyledButton";
import IconPayment from "../../components/icons/IconPayment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useAuth } from "../../context/AuthProvider";
import valid from "card-validator";

const CustomTextField = styled(TextField)(({ purple, ...props }) => ({
  color: "#02403C",
  "& .MuiOutlinedInput-root": {
    color: "#02403C",
    borderRadius: "10px",
    "& fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
    },
    "&.Mui-focused fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#02403C",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#02403C",
  },
}));

const CustomSelect = styled(Select)(({ purple, ...props }) => ({
  color: "#02403C",
  borderRadius: "10px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    borderColor: "#02403C",
    borderWidth: "1px",
  },
  "&:hover .MuiOutlinedInput-root": {
    borderRadius: "10px",
    borderColor: "#02403C !important",
    borderWidth: "1px !important",
  },
  "&.Mui-focused .MuiOutlinedInput-root": {
    borderRadius: "10px",
    borderColor: "#02403C",
    borderWidth: "1px",
  },
  "& fieldset": {
    color: "#02403C",
    borderColor: "#02403C",
    borderWidth: "1px",
  },
  "&:hover fieldset": {
    color: "#02403C",
    borderColor: "#02403C !important",
    borderWidth: "1px !important",
  },
  "&.Mui-focused fieldset": {
    color: "#02403C",
    borderColor: "#02403C",
    borderWidth: "1px",
  },
}));

const Payment = () => {
  const navigate = useNavigate();
  const { userId } = useAuth();

  const [cardNumber, setCardNumber] = useState("");
  const [isCardValid, setIsCardValid] = useState(true);
  const [cardDate, setCardDate] = useState("");
  const [isDateValid, setIsDateValid] = useState(true);
  const [cardCVV, setCardCVV] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [docType, setDocType] = useState("");
  const [docNumber, setDocNumber] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateCardNumber = (number) => {
    const cleanNumber = number.replace(/-/g, ""); // Elimina guiones para validación
    const validation = valid.number(cleanNumber);
    setIsCardValid(validation.isValid);
    return validation.isValid;
  };

  const formatCardNumber = (number) => {
    const cleanNumber = number.replace(/[^0-9]/g, ""); // Solo dígitos
    return (
      cleanNumber
        .match(/.{1,4}/g) // Divide en bloques de 4 caracteres
        ?.join("-") || ""
    ); // Une con guiones
  };

  const validateCardDate = (date) => {
    const dateRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    const isValid = dateRegex.test(date);
    setIsDateValid(isValid);
    return isValid;
  };

  const handleNumericInput = (value) => value.replace(/[^0-9]/g, "");

  const handleCardNumberChange = (event) => {
    const value = event.target.value;
    const formattedValue = formatCardNumber(value);
    setCardNumber(formattedValue);
    validateCardNumber(formattedValue);
  };

  const handleCardDateChange = (event) => {
    const value = event.target.value.replace(/[^0-9/]/g, "").slice(0, 5);
    if (value.length === 2 && !value.includes("/")) {
      setCardDate(`${value}/`);
    } else {
      setCardDate(value);
    }
    validateCardDate(value);
  };

  const handleCVVChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, "").slice(0, 4);
    setCardCVV(value);
  };

  const handleDocNumberChange = (event) => {
    const value = handleNumericInput(event.target.value);
    setDocNumber(value);
  };

  const handlePhoneChange = (event) => {
    const value = handleNumericInput(event.target.value);
    setPhone(value);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [loading, setLoading] = useState(false);

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const checkEpayco = () => {
      if (window.ePayco) {
        console.log("Epayco disponible");
      } else {
        console.log("Esperando Epayco...");
        setTimeout(checkEpayco, 100); // Reintenta cada 100ms
      }
    };
    checkEpayco();
  }, []);

  const handleSubscribe = async () => {
    setLoading(true);
  };

  return (
    <PageContainerDashboard>
      <FlexColumn>
        <Toolbar />
        <Grid
          container
          spacing={2}
          sx={{ p: "20px", alignContent: "flex-start" }}
        >
          <Grid item xs={12}>
            <FlexRow justifycontent="space-between" alignItems="center">
              <Typography variant="h5">Compra de plan</Typography>
              <FlexColumn alignItems="center" gap="8px">
                <StyledButton
                  sx={{ width: "max-content", minWidth: "100%" }}
                  secondary="true"
                >
                  <Typography
                    variant="body2"
                    onClick={() => handleNavigation(`/dashboard`)}
                  >
                    ← Cambiar de plan
                  </Typography>
                </StyledButton>
              </FlexColumn>
            </FlexRow>

            <StyledDivider sx={{ marginBottom: "28px", marginTop: "28px" }} />
          </Grid>
          <MaxWidthContainer>
            <Grid
              container
              spacing={0}
              columns={{
                xs: 4,
                sm: 4,
                md: 12,
                xl: 12,
                alignContent: "flex-start",
              }}
            >
              <Grid item xs={4} sm={4} md={6} xl={6}>
                <FlexColumn gap="24px" sx={{ padding: "48px 104px 48px 80px" }}>
                  <Typography variant="h6">Metodo de pago</Typography>
                  <FlexColumn gap="16px">
                    <CustomTextField
                      label="Número de tarjeta"
                      variant="outlined"
                      fullWidth
                      value={cardNumber}
                      onChange={handleCardNumberChange}
                      error={!isCardValid}
                      helperText={!isCardValid && "Número de tarjeta inválido"}
                    />
                    <FlexRow gap="24px">
                      <CustomTextField
                        label="Fecha de expiración (MM/YY)"
                        variant="outlined"
                        fullWidth
                        value={cardDate}
                        onChange={handleCardDateChange}
                        error={!isDateValid}
                        helperText={!isDateValid && "Formato inválido"}
                      />
                      <CustomTextField
                        label="CVV"
                        variant="outlined"
                        fullWidth
                        value={cardCVV}
                        onChange={handleCVVChange}
                      />
                    </FlexRow>
                  </FlexColumn>
                  <FlexColumn gap="16px">
                    <Typography variant="h6">Datos generales</Typography>
                    <FlexRow gap="24px">
                      <CustomTextField
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        type="text"
                        value={name}
                        onChange={(event) => {
                          setName(event.target.value);
                        }}
                      />
                      <CustomTextField
                        label="Apellido"
                        variant="outlined"
                        fullWidth
                        type="text"
                        value={lastname}
                        onChange={(event) => {
                          setLastname(event.target.value);
                        }}
                      />
                    </FlexRow>
                    <FlexRow gap="24px">
                      <CustomSelect
                        label="Tipo de documento"
                        variant="outlined"
                        displayEmpty
                        value={docType}
                        onChange={(event) => {
                          setDocType(event.target.value);
                        }}
                        input={<OutlinedInput />}
                        renderValue={() => {
                          if (docType === "") {
                            return (
                              <Typography
                                variant="body1"
                                sx={{ color: "#02403C" }}
                              >
                                Tipo de documento
                              </Typography>
                            );
                          }
                          return docType;
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem disabled value="">
                          <em>
                            <Typography variant="body1">
                              Tipo de documento
                            </Typography>
                          </em>
                        </MenuItem>

                        <MenuItem key={"CC"} value={"CC"}>
                          <Typography variant="body1">CC</Typography>
                        </MenuItem>
                      </CustomSelect>
                      <CustomTextField
                        label="Número de documento"
                        variant="outlined"
                        fullWidth
                        value={docNumber}
                        onChange={handleDocNumberChange}
                      />
                    </FlexRow>
                    <FlexRow gap="24px">
                      <CustomTextField
                        label="Correo electrónico"
                        variant="outlined"
                        fullWidth
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        error={!isEmailValid}
                        helperText={
                          !isEmailValid && "Correo electrónico inválido"
                        }
                      />
                      <CustomTextField
                        label="Teléfono"
                        variant="outlined"
                        fullWidth
                        value={phone}
                        onChange={handlePhoneChange}
                      />
                    </FlexRow>

                    <FlexRow gap="24px">
                      <CustomTextField
                        label="Dirección"
                        variant="outlined"
                        fullWidth
                        type="text"
                        value={address}
                        onChange={(event) => {
                          setAddress(event.target.value);
                        }}
                      />
                      <CustomTextField
                        label="Ciudad"
                        variant="outlined"
                        fullWidth
                        type="text"
                        value={city}
                        onChange={(event) => {
                          setCity(event.target.value);
                        }}
                      />
                    </FlexRow>
                  </FlexColumn>
                  <FlexColumn gap="24px">
                    <FlexRow gap="16px">
                      <StyledButton
                        variant="outlined"
                        component="label"
                        secondary="true"
                      >
                        Cancelar
                      </StyledButton>
                      <StyledButton
                        variant="contained"
                        component="label"
                        fullWidth
                        onClick={handleSubscribe}
                        disabled={
                          /*loading ||
                          cardNumber === "" ||
                          cardDate === "" ||
                          cardCVV === "" ||
                          name === "" ||
                          lastname === "" ||
                          docType === "" ||
                          docNumber === "" ||
                          email === "" ||
                          phone === "" ||
                          address === "" ||
                          city === ""*/
                          false
                        }
                      >
                        {loading ? "Procesando..." : "Suscribirme"}
                      </StyledButton>
                    </FlexRow>
                    <Typography variant="body1">
                      Al proporcionarnos la información de su tarjeta, usted nos
                      autoriza a cargar su tarjeta para futuros pagos de acuerdo
                      con sus términos.
                    </Typography>
                  </FlexColumn>
                </FlexColumn>
              </Grid>
              <Grid item xs={4} sm={4} md={6} xl={6}>
                <FlexColumn
                  gap="36px"
                  sx={{
                    padding: "48px 56px",
                    background: "#DADFDF",
                    borderRadius: "12px",
                  }}
                >
                  <BorderCard padding="16px" sx={{ background: "transparent" }}>
                    <FlexColumn gap="16px" alignItems="flex-start">
                      <Typography variant="h6">Detalles del plan</Typography>
                      <FlexRow gap="8px">
                        <IconPayment sx={{ height: "60px" }} />
                        <FlexColumn justifycontent="space-between">
                          <Typography sx={{ color: "#02403C" }}>
                            Hoy: Enero - 01 - 2024
                          </Typography>
                          <Typography sx={{ color: "#02403C" }}>
                            Hasta: Febrero - 01 - 2024
                          </Typography>
                        </FlexColumn>
                      </FlexRow>
                      <FlexRow sx={{ flexWrap: "wrap" }}>
                        <FlexColumn
                          gap="4px"
                          sx={{ width: "33.3%", minWidth: "132px" }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ color: "#02403C50" }}
                          >
                            Plan actual
                          </Typography>
                          <Typography variant="body1" sx={{ color: "#02403C" }}>
                            Plan Pro
                          </Typography>
                        </FlexColumn>
                        <FlexColumn
                          gap="4px"
                          sx={{ width: "33.3%", minWidth: "132px" }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ color: "#02403C50" }}
                          >
                            Precio del plan
                          </Typography>
                          <Typography variant="body1" sx={{ color: "#02403C" }}>
                            $199 US
                          </Typography>
                        </FlexColumn>
                        <FlexColumn
                          gap="4px"
                          sx={{ width: "33.3%", minWidth: "132px" }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ color: "#02403C50" }}
                          >
                            Recurrencia
                          </Typography>
                          <Typography variant="body1" sx={{ color: "#02403C" }}>
                            Mensual
                          </Typography>
                        </FlexColumn>
                      </FlexRow>
                      <FlexColumn gap="4px">
                        <Typography variant="body1" sx={{ color: "#02403C50" }}>
                          Capacidad del plan
                        </Typography>
                        <Typography variant="body1" sx={{ color: "#02403C" }}>
                          15 modelos al mes o 10 GB de información.
                        </Typography>
                      </FlexColumn>
                      <StyledButton
                        variant="outlined"
                        component="label"
                        secondary="true"
                      >
                        Cambiar plan
                      </StyledButton>
                    </FlexColumn>
                  </BorderCard>
                  <FlexColumn gap="16px">
                    <Typography variant="h6">Metodo de pago</Typography>
                  </FlexColumn>
                  <FlexColumn gap="16px">
                    <FlexRow justifyContent="space-between">
                      <Typography variant="h6">Total a pagar hoy</Typography>
                      <Typography variant="h6">$199 USD / Mes</Typography>
                    </FlexRow>
                    <FlexRow gap="8px">
                      <LockOutlinedIcon />
                      <Typography variant="body1">
                        Garantizamos seguridad y protección, asegurando que
                        todas las transacciones estén protegidas con el más alto
                        nivel de seguridad.
                      </Typography>
                    </FlexRow>
                  </FlexColumn>
                </FlexColumn>
              </Grid>
            </Grid>
          </MaxWidthContainer>
        </Grid>
      </FlexColumn>
    </PageContainerDashboard>
  );
};

export default Payment;

import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FlexColumn from "../../shared/container/FlexColumn";
import { useApiClient } from "../../../api/apiService";
import { toast } from "react-toastify";
import StyledSkeletonRectangular from "../skeleton/StyledSkeletonRectangular";

const CustomBox = styled(Box)(({ theme, ...props }) => ({
  width: "100%",
  border: "1px dashed " + theme.palette.primary.main,
  borderRadius: "10px",
  textAlign: "center",
  cursor: "pointer",
  "&:hover": {
    border: "1px solid " + theme.palette.primary.main,
    background: "#EBFDEA",
  },
}));

const StyledInputBox = ({ onUploadComplete, loading, setLoading }) => {
  const { post } = useApiClient();
  const handlePost = async (acceptedFiles) => {
    setLoading(true);
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append("excelFile", file);
    try {
      const response = await post("load_model/", formData);
      if (onUploadComplete) {
        onUploadComplete(response);
        if (response.data.deleted_values) {
          toast.warning(
            "Atención: Se eliminaron columnas o filas con valores vacíos."
          );
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al cargar el archivo.");
      }
    }
    setLoading(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/csv": [".csv"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls"],
    },
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0) {
        handlePost(acceptedFiles);
      }
      if (fileRejections.length > 0) {
        const fileRejection = fileRejections[0];
        const error = fileRejection.errors[0];
        if (error) {
          toast.error(
            `Error: El archivo ${fileRejection.file.name} tiene un tipo no permitido.`
          );
        }
      }
    },
  });

  return (
    <CustomBox>
      {loading ? (
        <StyledSkeletonRectangular />
      ) : (
        <Box {...getRootProps()}>
          <input {...getInputProps()} />
          <FlexColumn
            padding="28px"
            gap="8px"
            alignItems="center"
            justifycontent="center"
          >
            <CloudUploadIcon sx={{ fontSize: 40, color: "#004d40" }} />
            <Typography variant="body1">.csv, .xlsx, .xls</Typography>
            <Typography variant="body1">
              200 MB peso máximo del archivo
            </Typography>
          </FlexColumn>
        </Box>
      )}
    </CustomBox>
  );
};

export default StyledInputBox;

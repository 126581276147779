import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

const CustomBox = styled(Button)(({ theme, yellow, purple, ...props }) => ({
  textTransform: "capitalize",
  textAlign: "left",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  "& .MuiTypography-h5": {
    fontSize: "20px",
    fontWeight: yellow || purple ? "400" : "700",
    color: "#02403C",
  },
  "& .MuiTypography-h6": {
    fontSize: "12px",
    color: yellow ? "#FF9B43" : purple ? "#4D033F" : "#02403C",
  },
}));

const StyledAppBarStep = (props) => {
  return <CustomBox {...props}>{props.children}</CustomBox>;
};

export default StyledAppBarStep;

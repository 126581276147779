import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  ListItemButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import logoCriteria from "../../../images/logoCriteria.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../../../context/AuthProvider";
import LogoutModal from "../../shared/modal/LogoutModal";
import FlexRow from "../../shared/container/FlexRow";
import FlexColumn from "../../shared/container/FlexColumn";
import HomeIcon from "@mui/icons-material/Home";
import IconGlossary from "../../icons/IconGlossary";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { DataContext } from "../../../context/DataContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconStack from "../../icons/IconStack";
import IconStepperContainer from "../../shared/container/IconStepperContainer";
import IconTrending from "../../icons/IconTrending";
import IconBalance from "../../icons/IconBalance";
import IconSearch from "../../icons/IconSearch";
import IconSchema from "../../icons/IconSchema";
import StyledAppBarStep from "./StyledAppBarStep";

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  position: "relative",
  background: theme.palette.background.default,
  color: theme.palette.primary.main,
  zIndex: theme.zIndex.drawer + 1,
}));

const CustomListItemButton = styled(ListItemButton)(({ theme, ...props }) => ({
  display: "flex",
  justifyContent: "center",
  padding: "12px",
  color:
    props.path === props.actualpath ||
    props.secondpath === props.actualpath ||
    props.toogle === "true"
      ? theme.palette.secondary.main
      : theme.palette.primary[50],
  "&:hover": {
    color: theme.palette.secondary.main, // Puedes ajustar el color del hover si es necesario
  },
}));

const CustomTooltip = styled(Tooltip)(({ theme, ...props }) => ({
  "& span": {
    height: "24px !important",
  },
}));

const StyledAppBarDashboardSteps = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleLogout } = useAuth();
  const actualpath = location.pathname;
  const { actualData } = useContext(DataContext);

  const logout = () => {
    handleNavigation("/");
    handleLogout();
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const steps = [
    "Visualización de modelo",
    "Rendimiento técnico",
    "Detección de sesgos",
    "Mitigación de sesgos",
    "Predicción de nuevos datos",
  ];

  const stepRoutes = [
    "data-view",
    "performance",
    "detection",
    "mitigation",
    "prediction",
  ];

  // Determina el índice del paso actual
  const currentStepIndex = steps.indexOf(actualData?.phase || "");

  return (
    <CustomAppBar {...props}>
      <LogoutModal open={open} onClose={handleClose} handleLogout={logout} />
      <Toolbar sx={{ padding: "0px !important" }}>
        <FlexColumn width="100%" sx={{ minWidth: "1280px" }}>
          <FlexRow
            justifyContent="space-between"
            width="100%"
            padding="20px 24px"
          >
            <img
              src={logoCriteria}
              alt="logo"
              onClick={() => handleNavigation("/")}
            />
            {props.children}
            <Button sx={{ marginLeft: "auto" }} onClick={() => setOpen(true)}>
              <LogoutIcon fontSize="large" />
            </Button>
          </FlexRow>
          <FlexRow width="100%">
            <FlexRow sx={{ background: "#02403C" }} padding="0px 24px">
              <CustomListItemButton
                actualpath={actualpath}
                path="/dashboard"
                onClick={() => handleNavigation("/dashboard")}
              >
                <HomeIcon sx={{ fontSize: 32 }} />
              </CustomListItemButton>
              <CustomListItemButton
                actualpath={actualpath}
                path="/dashboard/glossary"
                onClick={() => handleNavigation("/dashboard/glossary")}
              >
                <IconGlossary sx={{ width: "30px", height: "36px" }} />
              </CustomListItemButton>
              <CustomListItemButton
                actualpath={actualpath}
                path="/settings"
                onClick={() => handleNavigation("/settings")}
              >
                <SettingsIcon sx={{ width: "30px", height: "36px" }} />
              </CustomListItemButton>
            </FlexRow>
            <Box
              sx={{
                background: "#EBFDEA",
                width: "100%",
                padding: "0px 24px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {actualData?.name && (
                <FlexRow width="100%" alignItems="center">
                  <Button>
                    <ArrowBackIosNewIcon
                      onClick={() => handleNavigation("/dashboard")}
                    />
                  </Button>
                  {actualData && (
                    <Typography variant="h5">{actualData.name}</Typography>
                  )}
                </FlexRow>
              )}
            </Box>
          </FlexRow>
          {actualData && (
            <Accordion
              sx={{
                margin: "0px !important",
                borderRadius: "0px 0px 16px 16px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <FlexRow width="100%" gap="12px">
                  {steps.map((step, index) => {
                    const isYellow = index === currentStepIndex;
                    const isPurple = index > currentStepIndex;

                    return (
                      <IconStepperContainer
                        key={step}
                        yellow={isYellow}
                        purple={isPurple}
                      >
                        {index === 0 && (
                          <Tooltip title={"Visualización de Modelo"} arrow>
                            <span style={{ height: "24px" }}>
                              <IconStack />
                            </span>
                          </Tooltip>
                        )}
                        {index === 1 && (
                          <Tooltip title={"Rendimiento Técnico"} arrow>
                            <span style={{ height: "24px" }}>
                              <IconTrending />
                            </span>
                          </Tooltip>
                        )}
                        {index === 2 && (
                          <Tooltip title={"Detección de Sesgos"} arrow>
                            <span style={{ height: "24px" }}>
                              <IconSearch />
                            </span>
                          </Tooltip>
                        )}
                        {index === 3 && (
                          <Tooltip title={"Mitigación de Sesgos"} arrow>
                            <span style={{ height: "24px" }}>
                              <IconBalance />
                            </span>
                          </Tooltip>
                        )}
                        {index === 4 && (
                          <Tooltip title={"Predicción de Nuevos Datos"} arrow>
                            <span style={{ height: "24px" }}>
                              <IconSchema />
                            </span>
                          </Tooltip>
                        )}
                      </IconStepperContainer>
                    );
                  })}
                </FlexRow>
              </AccordionSummary>
              <AccordionDetails>
                <FlexRow
                  width="100%"
                  gap="12px"
                  sx={{ padding: "0px 24px 0px 0px" }}
                >
                  {steps.map((step, index) => {
                    const isYellow = index === currentStepIndex;
                    const isPurple = index > currentStepIndex;

                    return (
                      <StyledAppBarStep
                        key={step}
                        yellow={isYellow}
                        purple={isPurple}
                        onClick={() =>
                          handleNavigation(
                            `/dashboard/${actualData?.id_model}/${stepRoutes[index]}`
                          )
                        }
                      >
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "16px", color: "#02403C" }}
                        >
                          {index === 0 ? "Inicio" : `Fase ${index}`}
                        </Typography>
                        <Typography variant="h5">{step}</Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontSize: "12px", color: "#02403C" }}
                        >
                          {isYellow
                            ? "En progreso"
                            : isPurple
                            ? "Pendiente"
                            : "Completado"}
                        </Typography>
                      </StyledAppBarStep>
                    );
                  })}
                </FlexRow>
              </AccordionDetails>
            </Accordion>
          )}
        </FlexColumn>
      </Toolbar>
    </CustomAppBar>
  );
};

export default StyledAppBarDashboardSteps;

import React from "react";
import { Grid, Typography } from "@mui/material";
import StyledDivider from "../../components/common/divider/StyledDivider";
import {
  PageContainerDashboard,
  FlexColumn,
} from "../../components/shared/container/Index";
import "react-toastify/dist/ReactToastify.css";
import StyledAccordion from "../../components/common/accordion/StyledAccordion";
import {
  CalibracionBuena,
  CalibracionMala,
  CurvaPrecision,
  CurvaROC,
  CurvaROC2,
  EqualOpportunities,
  EqualOpportunities2,
  JusticiaPorCalibracion,
  JusticiaPorCalibracion2,
} from "../../images/Index";

const Glossary = () => {
  return (
    <PageContainerDashboard>
      <Grid
        container
        spacing={3}
        sx={{ p: "20px", alignContent: "flex-start" }}
      >
        <Grid item xs={12}>
          <FlexColumn gap="8px">
            <Typography variant="h5">Glosario</Typography>
            <Typography variant="body1">
              En esta sección encontrarás el glosario.
            </Typography>
            <StyledDivider />
          </FlexColumn>
        </Grid>
        <Grid item xs={12}>
          <FlexColumn gap="20px">
            <Typography variant="h5" sx={{ margin: "10px 0px" }}>
              Terminologías generales
            </Typography>
            <StyledAccordion
              title="Aprendizaje supervisado"
              details="en inteligencia artificial, consiste en entrenar un modelo para realizar predicciones o tomar decisiones basadas en datos previamente etiquetados. El algoritmo recibe un conjunto de entrenamiento con entradas y sus correspondientes salidas deseadas, aprendiendo a mapearlas con precisión. Por ejemplo, en la clasificación de imágenes de animales, el modelo analiza fotos etiquetadas como 'gato' o 'perro' para aprender a diferenciarlos. Luego, al enfrentarse a una nueva imagen, predice la categoría según lo aprendido."
            />
            <StyledAccordion
              title="Variable protegida"
              details="es una característica o atributo asociado a una catacterística poblacional que se considera sensible o que podría dar lugar a discriminación, sesgos u otros efectos adversos al emplear un modelo de aprendizaje. Ejemplos de variables protegidas incluyen género, sexo, raza, edad, nivel socioeconómico, nacionalidad, orientación sexual, ubicación de residencia, entre otros."
            />
            <StyledAccordion
              title="Sesgo Algorítmico (Algorithmic Bias)"
              details="cuando un modelo o algoritmo produce resultados que son sistemáticamente injustos o discriminatorios para ciertos grupos demográficos, debido a datos sesgados o a la forma con la que fue entrenado."
            />
            <StyledAccordion
              title="Mitigación de Sesgos (Bias Mitigation)"
              details="está asociada a estrategias o técnicas implementadas para reducir o eliminar el sesgo en modelos de aprendizaje de máquinas y sus resultados."
            />
            <StyledAccordion
              title="Umbral"
              details="en modelos que predicen valores binarios, el umbral (o threshold) es el valor que separa las dos posibles clases o valores. Si la probabilidad predicha por el modelo supera este valor, se clasifica como una clase, usualmente la positiva o 1; si no, se clasifica como 0. Por ejemplo, con un umbral de 0.5, probabilidades mayores a 0.5 se asignan a la clase positiva, y menores o iguales, a la clase negativa."
            />

            <Typography variant="h5" sx={{ margin: "10px 0px" }}>
              Métricas generales de rendimiento técnico
            </Typography>
            <StyledAccordion
              title="Calibración"
              details="indica qué tan bien las probabilidades predichas por el modelo reflejan la realidad. Es decir, un modelo está bien calibrado cuando la probabilidad que asigna a un evento coincide con la frecuencia real de ese evento. Por ejemplo, si un modelo predice que hay un 70% de probabilidad de que algo ocurra, entonces, en un conjunto grande de predicciones, el 70% de las veces ese evento realmente debería ocurrir. La calibración asegura que las probabilidades predichas sean precisas y significativas."
              image={CalibracionBuena}
              details2="En la práctica, esta métrica es importante porque mejora la confianza y la interpretabilidad de las predicciones. Si un modelo está mal calibrado, puede subestimar o sobreestimar las probabilidades, lo que lleva a decisiones incorrectas o a una mala gestión del riesgo en aplicaciones críticas, como en sistemas financieros o de salud."
              image2={CalibracionMala}
            />
            <StyledAccordion
              title="Curva ROC"
              details="la curva ROC (Receiver Operating Characteristic) es una herramienta gráfica utilizada para evaluar el rendimiento de un modelo de clasificación binaria, es decir, un modelo que predice uno de dos posibles resultados (como 'aprobado' o 'rechazado' o, “positivo” o “negativo”). /n La curva ROC muestra la relación entre dos métricas clave a medida que varías el umbral de decisión del modelo: /n 1.Tasa de verdaderos positivos (True Positive Rate o TPR): También llamada recall o sensibilidad, mide la proporción de casos positivos que el modelo predice correctamente. /n 2.Tasa de falsos positivos (False Positive Rate o FPR): Mide la proporción de casos negativos que el modelo predice incorrectamente como positivos."
              details2="En el gráfico, el eje Y representa la tasa de verdaderos positivos (TPR) y el eje X representa la tasa de falsos positivos (FPR). A medida que cambia el umbral de decisión del modelo (el punto en el que se define si una predicción es positiva o negativa), se obtienen diferentes combinaciones de TPR y FPR, que se trazan en la curva ROC."
              image2={CurvaROC2}
            />
            <StyledAccordion
              title="AUC"
              details="la métrica AUC mide el área bajo la curva ROC del modelo (conocida como AUC-ROC) y está definida por un solo número que resume el rendimiento del modelo. Cuanto más cerca esté el valor del AUC de 1, mejor es el modelo. Un AUC de 0.5 indica que el modelo no tiene ninguna capacidad de clasificación (es equivalente a lanzar una moneda). Cuando el AUC toma valores cercanos a 0 indica que el modelo presenta altos errores en las predicciones."
            />
            <StyledAccordion
              title="Curva Precision-Recall"
              details="La curva Precision-Recall es una herramienta gráfica utilizada para evaluar el rendimiento de modelos de clasificación binaria, especialmente cuando el conjunto de datos está desbalanceado. En esta curva, se representan la precisión y el recall (sensibilidad) en función de diferentes umbrales de decisión aplicados a las predicciones de probabilidad del modelo.  Estos valores se trazan en un gráfico, con la precisión en el eje vertical y el recall en el eje horizontal."
              details2="Un clasificador perfecto tendría una curva que alcanza el punto (1,1), lo que indica tanto máxima precisión como máximo recall. En cambio, un clasificador sin capacidad predictiva tendría una curva que se aproxima al valor de la proporción de etiquetas positivas en el conjunto de datos. En general, se busca que la curva esté lo más cerca posible de la esquina superior derecha, reflejando un alto rendimiento en ambas métricas, como se observa en la siguiente gráfica:"
              image2={CurvaPrecision}
            />
            <Typography variant="h5" sx={{ margin: "10px 0px" }}>
              Métricas binarias
            </Typography>
            <StyledAccordion
              title="Accuracy (Exactitud)"
              details="la “exactitud” indica qué tan bien el modelo hace predicciones correctas. Si el modelo intenta predecir algo (como si un correo es spam o no), esta métrica indica el porcentaje de veces que el modelo acierta en sus predicciones, en comparación con el total de predicciones que hace. Por ejemplo, si predice correctamente 8 de 10 correos, significa que la exactitud es del 80%. O, en otras palabras, acierta el 80% de las veces."
            />
            <StyledAccordion
              title="Recall (Sensibilidad)"
              details="El “recall”, también llamado sensibilidad indica cuántos elementos positivos logra predecir correctamente en comparación con todos los elementos positivos que realmente existen. Un “recall” alto significa que el modelo es bueno para encontrar esos elementos positivos, mientras que un 'recall' bajo significa que podría estar ignorando algunos de ellos. Siguiendo el ejemplo del correo, si de 100 correos que son realmente spam, el modelo identificó correctamente 80, el recall sería del 80%. Esto significa que el modelo encontró el 80% de los correos que eran spam, pero se le escaparon 20 que también eran spam."
            />
            <StyledAccordion
              title="Especificidad"
              details="la especificidad (o tasa de verdaderos negativos) mide qué tan bien el modelo identifica los casos negativos correctamente entre todos los negativos que existen. En otras palabras, se enfoca en cuántos de los casos que no deberían ser identificados como positivos fueron reconocidos correctamente como negativos. Por ejemplo, si hay 100 correos que no son spam y el modelo identificó correctamente 90 de ellos como 'no spam', la especificidad sería del 90%. Esto significa que el modelo acertó en un 90% de los casos al no marcar como spam esos correos que en realidad no lo eran."
            />
            <StyledAccordion
              title="Precision (Precisión)"
              details="la “precisión” mide cuán correcto es el modelo cuando predice algo como positivo. Es decir, entre todos los casos que el modelo dijo que eran positivos, ¿cuántos realmente lo eran? Si predice que 50 correos son spam, pero sólo 40 de ellos realmente lo son, la precisión sería del 80%. Esto significa que el modelo acertó en el 80% de los casos en los que dijo que un correo era spam, pero se equivocó en el 20%, marcando correos como spam cuando en realidad no lo eran."
            />
            <StyledAccordion
              title="F1-Score"
              details="es una medida que combina tanto la precisión como el recall (sensibilidad) en un solo valor, y es especialmente útil cuando se desea encontrar un equilibrio entre ambos. El F1 score es el promedio armónico entre la precisión y el recall, lo que significa que le da más peso a los valores bajos. Siguiendo el ejemplo de los correos, si el modelo tiene una alta precisión (acierta la mayoría de los correos que clasifica como spam) pero un bajo recall (se le escapan muchos correos que eran spam), o viceversa, el F1 score reflejará este desequilibrio. El F1 score será alto solo si tanto la precisión como el recall son altos."
            />
            <Typography variant="h5" sx={{ margin: "10px 0px" }}>
              Definiciones y métricas de justicia
            </Typography>
            <StyledAccordion
              title="Demographic parity"
              details="esta definición define la idea de que un modelo es justo si la tasa de decisiones positivas (por ejemplo, aprobar un crédito, ofrecer un empleo) es la misma para todos los grupos demográficos o variables protegidas, es decir, es independiente de características como género, raza, edad, etc. Por ejemplo, en un modelo que decide la aprobación de préstamos, la tasa de aprobación debería ser similar entre hombres y mujeres (o entre diferentes razas o edades) para cumplir con demographic parity. Si un grupo recibe aprobaciones mucho más frecuentemente que otro, esto indicaría un posible sesgo."
            />
            <StyledAccordion
              title="Equalized Odds"
              details="es un criterio de justicia que busca asegurar que el modelo trate de manera similar a diferentes grupos demográficos, en términos de sus errores y aciertos. Bajo este criterio, un modelo cumple con equalized odds si, para cada grupo (por ejemplo, según género, raza, etc.), las tasas de: /n 1.Verdaderos positivos (cuando el modelo predice correctamente un resultado positivo) son iguales. /n 2.Falsos positivos (cuando el modelo predice incorrectamente un resultado positivo) también son iguales."
              details2="Esto significa que, dado un resultado real (positivo o negativo), la probabilidad de que el modelo haga una predicción correcta o incorrecta es la misma para todos los grupos. El objetivo es que no haya disparidades entre grupos en cuanto a cómo se distribuyen los errores (falsos positivos y falsos negativos) del modelo."
            />
            <StyledAccordion
              title="Justicia por Calibración"
              details="la justicia por calibración busca que el poder predictivo de un modelo se mantenga a lo largo de las categorías de una variable protegida. Un modelo está bien calibrado si, para cualquier nivel de probabilidad predicha, la proporción de individuos que realmente tienen el resultado positivo es la misma en todos los grupos. En otras palabras, si el modelo predice que alguien tiene un 70% de probabilidad de obtener un resultado positivo (como recibir un préstamo), entonces, para todas las personas a quienes se les predijo esa probabilidad, el 70% realmente debería recibir el préstamo, independientemente de a qué grupo pertenecen (género, raza, etc.). Gráficamente, en la figura de la izquierda se observa un modelo calibrado; en la derecha, un modelo sin calibrar que puede evidenciar presencia de sesgos:"
              image2={JusticiaPorCalibracion2}
            />
            <StyledAccordion
              title="False Positive Rate Parity"
              details="es un criterio de equidad que busca asegurar que la tasa de falsos positivos sea la misma entre diferentes grupos demográficos o variables protegidas. Cabe recordar que un falso positivo ocurre cuando el modelo predice incorrectamente un resultado positivo para una persona que en realidad no debería recibirlo. Bajo este criterio, un modelo cumple con false positive rate parity si, para cada grupo (como género, raza o edad), la proporción de falsos positivos es similar. Esto significa que la probabilidad de que alguien reciba una predicción positiva incorrecta (cuando en realidad no debería) es igual entre todos los grupos."
            />
            <StyledAccordion
              title="True Positive Rate Parity"
              details="es un criterio de equidad que el modelo tenga el mismo true positive rate (TPR), o tasa de verdaderos positivos, para todos los grupos demográficos o variables protegidas (como género, raza, edad, etc.). Cabe recordar que la tasa de verdaderos positivos (TPR) se refiere a la proporción de casos positivos que el modelo predice correctamente. Por ejemplo, si un modelo está prediciendo quién debe recibir un préstamo, la tasa de verdaderos positivos sería la proporción de personas que realmente son solventes y que el modelo clasifica correctamente como aprobadas para el préstamo. Bajo esta definición, el objetivo es que todos los grupos demográficos tengan la misma tasa de verdaderos positivos. Esto significa que, independientemente de a qué grupo pertenezca una persona, la probabilidad de que el modelo prediga correctamente un resultado positivo (cuando efectivamente debería ser positivo) debe ser la misma."
            />

            <Typography variant="h5" sx={{ margin: "10px 0px" }}>
              Otras métricas
            </Typography>
            <StyledAccordion
              title="Proportional Parity"
              details="es un criterio de equidad en los modelos de inteligencia artificial que sugiere que la proporción de resultados positivos asignados por el modelo debe ser proporcional a la distribución de esos resultados en la población real. Esto significa que la proporción de personas que reciben una decisión positiva (como ser aprobados para un crédito o ser contratados para un empleo) debe reflejar la proporción de personas de ese grupo en la población general."
            />
            <StyledAccordion
              title="False negative rate parity"
              details="esta métrica busca que la tasa de falsos negativos sea similar o igual para los grupos demográficos o sensibles. Se calcula comparando la tasa de falsos negativos entre cada grupo y determinando si hay un sesgo o disparidad significativa en las tasas."
            />
            <StyledAccordion
              title="False omission rate parity"
              details="con esta métrica se busca que la fracción de falsos negativos dentro de los negativos predichos sea similar entre cada grupo demográfico o categoría sensible."
            />
            <StyledAccordion
              title="True negative rate parity"
              details="esta métrica busca que la tasa de verdaderos negativos sea similar o igual para diferentes grupos. Se calcula comparando la tasa de verdaderos negativos entre diferentes grupos demográficos o categorías sensibles y determinando si hay un sesgo o disparidad significativa en las tasas."
            />
          </FlexColumn>
        </Grid>
      </Grid>
    </PageContainerDashboard>
  );
};

export default Glossary;

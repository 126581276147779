import React, { useContext, useState, useEffect } from "react";
import { useApiClient } from "../../../api/apiService";
import { toast } from "react-toastify";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Typography, styled } from "@mui/material";
import StyledButton from "../../common/button/StyledButton";
import { EchartsContext } from "../../../context/EchartsContext";
import { DataContext } from "../../../context/DataContext";
import { LogoPDF } from "../../../images/Index";
import styles from "./PdfStyles";
import { useAuth } from "../../../context/AuthProvider";

const CustomPDFDownloadLink = styled(PDFDownloadLink)(
  ({ theme, ...props }) => ({
    color: theme.palette.primary[50],
    textDecoration: "none",
    fontWeight: "500",
  })
);

const MyDocument = ({
  chartImages,
  texts,
  textsByVariable,
  userName,
  fairness,
}) => {
  const formatBulletPoint = (text) => {
    return <Text style={styles.bulletText}>• {text}</Text>;
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const renderTextWithBold = (text) => {
    // Expresión regular para detectar <strong> y </strong>
    const parts = text.split(/(<strong>|<\/strong>)/);

    return parts.map((part, index) => {
      if (part === "<strong>" || part === "</strong>") return null; // Ignora las etiquetas
      const isBold = parts[index - 1] === "<strong>";
      return (
        <Text key={index} style={isBold ? styles.subtitle : styles.text}>
          {part}
        </Text>
      );
    });
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.boxRow} wrap={false}>
          <View style={styles.boxColumn}>
            <Text style={styles.title}>Reporte de Detección de Sesgos</Text>
            <View style={styles.boxRow} wrap={false}>
              <Text style={styles.subtitle}>Fecha:</Text>
              <Text style={styles.greentext}>
                {new Date().toISOString().split("T")[0]}
              </Text>
              <Text style={styles.greentext}>-</Text>
              <Text style={styles.subtitle}>Usuario:</Text>
              <Text style={styles.greentext}>{userName}</Text>
            </View>
          </View>
          <Image style={styles.image} src={LogoPDF} />
        </View>
        <Text style={styles.text}>{texts && texts.intro}</Text>

        {fairness !== "dp" && fairness !== "ca" && (
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "20px",
            }}
          >
            <Text style={styles.greentext}>
              Diferencia con el grupo de referencia
            </Text>

            <View
              style={{ display: "flex", flexDirection: "row", gap: "40px" }}
            >
              <View style={{ display: "flex", flexDirection: "column" }}>
                <View
                  style={{ display: "flex", flexDirection: "row", gap: "2px" }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                      padding: "4px",
                      borderLeft: "0.5px solid #02403C",
                      alignItems: "flex-start",
                    }}
                  >
                    <View
                      style={{
                        minHeight: "40px",
                        minWidth: "40px",
                        backgroundColor: "#193F3B",
                      }}
                    />
                    <Text style={styles.greentext}>0</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                      padding: "4px",
                      borderLeft: "0.5px solid #02403C",
                      alignItems: "flex-start",
                    }}
                  >
                    <View
                      style={{
                        minHeight: "40px",
                        minWidth: "40px",
                        backgroundColor: "#79978D",
                      }}
                    />
                    <Text style={styles.greentext}>0.05</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                      padding: "4px",
                      borderLeft: "0.5px solid #02403C",
                      alignItems: "flex-start",
                    }}
                  >
                    <View
                      style={{
                        minHeight: "40px",
                        minWidth: "40px",
                        backgroundColor: "#F19F57",
                      }}
                    />
                    <Text style={styles.greentext}>0.10</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                      padding: "4px",
                      borderLeft: "0.5px solid #02403C",
                      alignItems: "flex-start",
                    }}
                  >
                    <View
                      style={{
                        minHeight: "40px",
                        minWidth: "40px",
                        backgroundColor: "#924553",
                      }}
                    />
                    <Text style={styles.greentext}>0.15</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                      padding: "4px",
                      borderLeft: "0.5px solid #02403C",
                      alignItems: "flex-start",
                    }}
                  >
                    <View
                      style={{
                        minHeight: "40px",
                        minWidth: "40px",
                        backgroundColor: "#652832",
                      }}
                    />
                    <Text style={styles.greentext}>{">"}0.2</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  padding: "4px",
                  borderLeft: "0.5px solid #02403C",
                  alignItems: "flex-start",
                }}
              >
                <View
                  style={{
                    minHeight: "40px",
                    minWidth: "40px",
                    backgroundColor: "#6A6667",
                  }}
                />
                <Text style={{ ...styles.greentext, width: "160px" }}>
                  Este color representa a la categoría de referencia, es decir,
                  aquella que tiene el mínimo valor en la métrica de justicia
                  seleccionada.
                </Text>
              </View>
            </View>
          </View>
        )}

        {chartImages["barDetectionCharts"]?.length > 0 && (
          <View style={styles.graphBox}>
            {chartImages["barDetectionCharts"].map((imgData, index) => {
              const variableKey = Object.keys(
                textsByVariable.texto_variables_protegidas
              )[index];
              const variableTexts =
                textsByVariable.texto_variables_protegidas[variableKey];

              return (
                <View
                  style={{ ...styles.graphBox, alignItems: "flex-start" }}
                  wrap={false}
                >
                  <Text style={styles.title}>{variableKey}</Text>
                  <Text style={styles.greentext}>
                    ({variableTexts?.titulo})
                  </Text>
                  <View
                    style={{ ...styles.boxRow, height: "160px" }}
                    key={index}
                  >
                    <View style={styles.graphBox}>
                      {chartImages["rocDetectionCharts"][index] && (
                        <Image
                          style={styles.graph}
                          src={chartImages["rocDetectionCharts"][index]}
                        />
                      )}
                    </View>
                    <View style={styles.graphBox}>
                      {imgData && <Image style={styles.graph} src={imgData} />}
                    </View>
                  </View>
                  <View
                    style={{ ...styles.graphBox, alignItems: "flex-start" }}
                  >
                    <Text style={styles.text}>{variableTexts?.parrafo}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        )}

        {chartImages["tprBarDetectionCharts"]?.length > 0 && (
          <View style={styles.graphBox}>
            {chartImages["tprBarDetectionCharts"].map((imgData, index) => {
              const variableKey = Object.keys(
                textsByVariable.texto_variables_protegidas
              )[index];
              const variableTexts =
                textsByVariable.texto_variables_protegidas[variableKey];

              return (
                <View
                  style={{ ...styles.graphBox, alignItems: "flex-start" }}
                  wrap={false}
                >
                  <Text style={styles.title}>{variableKey}</Text>
                  <Text style={styles.greentext}>
                    ({variableTexts?.titulo})
                  </Text>
                  <View
                    style={{ ...styles.boxRow, height: "160px" }}
                    key={index}
                  >
                    <View style={styles.graphBox}>
                      {chartImages["rocDetectionCharts"][index] && (
                        <Image
                          style={styles.graph}
                          src={chartImages["rocDetectionCharts"][index]}
                        />
                      )}
                    </View>
                    <View style={styles.graphBox}>
                      {imgData && <Image style={styles.graph} src={imgData} />}
                    </View>
                    <View style={styles.graphBox}>
                      {chartImages["fprBarDetectionCharts"][index] && (
                        <Image
                          style={styles.graph}
                          src={chartImages["fprBarDetectionCharts"][index]}
                        />
                      )}
                    </View>
                  </View>
                  <View
                    style={{ ...styles.graphBox, alignItems: "flex-start" }}
                  >
                    <Text style={styles.text}>{variableTexts?.parrafo}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        )}

        {chartImages["softLineDetectionCharts"]?.length > 0 && (
          <View style={styles.graphBox}>
            <Text style={styles.greentext}>
              Curvas de Calibración por Variable Protegida
            </Text>
            <Text style={styles.text}>
              {renderTextWithBold(textsByVariable.texto_variables_protegidas)}
            </Text>
            {chunkArray(chartImages["softLineDetectionCharts"], 3).map(
              (chunk, chunkIndex) => (
                <View
                  style={{ ...styles.boxRow }}
                  wrap={false}
                  key={chunkIndex}
                >
                  {chunk.map((imgData, index) => (
                    <View style={styles.graphBoxHalfWidth} key={index}>
                      {imgData && <Image style={styles.graph} src={imgData} />}
                    </View>
                  ))}
                </View>
              )
            )}
          </View>
        )}

        {chartImages["calibrationLineDetectionCharts"]?.length > 0 && (
          <View style={styles.graphBox}>
            <Text style={styles.greentext}>
              Curvas de Calibración por Variable Protegida
            </Text>
            <Text style={styles.text}>
              {renderTextWithBold(textsByVariable.texto_variables_protegidas)}
            </Text>
            {chunkArray(chartImages["calibrationLineDetectionCharts"], 3).map(
              (chunk, chunkIndex) => (
                <View
                  style={{ ...styles.boxRow }}
                  wrap={false}
                  key={chunkIndex}
                >
                  {chunk.map((imgData, index) => (
                    <View style={styles.graphBoxHalfWidth} key={index}>
                      {imgData && <Image style={styles.graph} src={imgData} />}
                    </View>
                  ))}
                </View>
              )
            )}
          </View>
        )}

        <View wrap={false}>
          <Text style={styles.title}>Recomendaciones y conclusiones</Text>
          <Text style={styles.text}>{texts && texts.conclusion}</Text>
        </View>
      </Page>
    </Document>
  );
};

const DetectionReport = ({
  textsByVariable,
  fairness,
  variables,
  tuples,
  loading,
}) => {
  const { post } = useApiClient();
  const { eCharts } = useContext(EchartsContext);
  const { actualData } = useContext(DataContext);
  const { userName } = useAuth();
  const [texts, setTexts] = useState(null);
  const [pdfGenerating, setPdfGenerating] = useState(false);

  const getTexts = async (actualData) => {
    if (
      !loading &&
      actualData.intersect_dataframe &&
      actualData.metrics_dataframe
    ) {
      const body = {
        dataframe: actualData.intersect_dataframe,
        name: actualData.name,
        date: actualData.creation_date,
        definition: fairness,
        protected_variables: variables,
        intersecting_variables: tuples,
        fairness_metrics: actualData.metrics_dataframe,
      };
      try {
        const response = await post("generate_bias_detection_report/", body);
        setTexts(response.data);
      } catch (error) {
        if (error.response && error.response.data) {
          toast.error(`Error: ${error.response.data.msg}`);
        } else {
          toast.error(
            "Error: Ocurrió un error al cargar la información de las graficas."
          );
        }
      }
    }
  };

  useEffect(() => {
    if (
      actualData &&
      actualData.intersect_dataframe &&
      actualData.metrics_dataframe
    ) {
      getTexts(actualData);
    }
  }, [actualData]);

  const handlePdfStatus = (loading) => {
    setPdfGenerating(loading);
  };

  return (
    <StyledButton
      variant="contained"
      disabled={loading || pdfGenerating || !textsByVariable}
    >
      <Typography variant="body1">
        <CustomPDFDownloadLink
          document={
            <MyDocument
              chartImages={eCharts}
              texts={texts}
              textsByVariable={textsByVariable}
              userName={userName}
              fairness={fairness}
            />
          }
          fileName={"Deteccion_de_sesgos_" + actualData.name + ".pdf"}
        >
          {({ blob, url, loading: pdfLoading, error }) => {
            handlePdfStatus(pdfLoading);
            return pdfLoading || loading
              ? "Generando documento..."
              : "Descargar reporte de detección de sesgos";
          }}
        </CustomPDFDownloadLink>
      </Typography>
    </StyledButton>
  );
};

export default DetectionReport;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import StyledDivider from "../../components/common/divider/StyledDivider";
import {
  PageContainerDashboard,
  BorderCard,
  FlexColumn,
  FlexRow,
} from "../../components/shared/container/Index";
import "react-toastify/dist/ReactToastify.css";
import StyledButton from "../../components/common/button/StyledButton";
import SearchIcon from "@mui/icons-material/Search";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toast } from "react-toastify";
import { useApiClient } from "../../api/apiService";
import { useAuth } from "../../context/AuthProvider";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GradeIcon from "@mui/icons-material/Grade";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import EditNameModal from "../../components/shared/modal/EditNameModal";
import DeleteModelModal from "../../components/shared/modal/DeleteModelModal";
import { DataContext } from "../../context/DataContext";

const CustomTextField = styled(TextField)(({ theme }) => ({
  background: "#FFF",
  borderRadius: "10px",
  height: "100%",
  marginTop: "8px",
  "& .MuiOutlinedInput-input": {
    color: "#02403C",
  },
}));

const CustomDatePicker = styled(DatePicker)({
  minWidth: "0px !important",
  background: "#FFF !important",
  "& .MuiInputBase-root": {
    color: "#02403C !important",
    padding: "8px 12px",
  },
  "& .MuiInputBase-input": {
    color: "#02403C !important",
    padding: "8px 12px",
  },
  "& .MuiSvgIcon-root": {
    color: "#02403C !important",
  },
  "& .MuiButtonBase-root": {
    color: "#02403C !important",
  },
  "& .MuiPickersDay-root": {
    color: "#02403C !important",
  },
  "& .MuiPickersDay-root.Mui-selected": {
    backgroundColor: "#02403C !important",
    color: "#FFF !important",
  },
  "& .MuiPickersDay-root:hover": {
    backgroundColor: "#E0F2F1 !important",
  },
});

const Dashboard = () => {
  const { userId, accessToken } = useAuth();
  const { selectData, deleteData } = useContext(DataContext);
  const { get, patch, del } = useApiClient();
  const navigate = useNavigate();
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(0);
  const [nameChange, setNameChange] = useState("");

  const [activeFilter, setActiveFilter] = useState("recientes");

  useEffect(() => {
    setTimeout(function () {
      loadModels();
    }, 1000);
    deleteData();
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const filteredData = models
    .filter((model) => {
      const matchesSearchTerm = model.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      const matchesDateRange =
        (!startDate || model.creation_date >= startDate) &&
        (!endDate || model.creation_date <= endDate);

      if (activeFilter === "favoritos") {
        return model.favorite && matchesSearchTerm && matchesDateRange;
      }

      return matchesSearchTerm && matchesDateRange;
    })
    .sort((a, b) => {
      if (activeFilter === "recientes") {
        return new Date(b.creation_date) - new Date(a.creation_date);
      }
      return 0; // No ordenar si no está activo el filtro "recientes"
    });

  const handleFilterClick = (filter) => {
    if (filter === activeFilter) {
      setActiveFilter("");
    } else {
      setActiveFilter(filter);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const loadModels = async () => {
    setModels([]);
    try {
      const response = await get("get_models/" + userId);
      setModels(response.data);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.messages[0].message}`);
        handleNavigation("/login");
      } else {
        toast.error("Error: Ocurrió un error al cargar los modelos.");
      }
    }
  };

  const handleSelectModel = async (modelId) => {
    try {
      const response = await get("get_model/" + modelId);
      selectData(response.data);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data}`);
      } else {
        toast.error("Error: Ocurrió un error al cargar el modelo.");
      }
    }
  };

  const addModelFavorites = async (id, favorite) => {
    const body = { favorite: favorite };
    try {
      const response = await patch("update_favorite/" + id, body);
      toast.success("Se ha actualizado el modelo con exito.");
      loadModels();
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al cambiar el estado del modelo.");
      }
    }
  };

  const editModelName = async () => {
    const body = { new_name: nameChange };
    try {
      const response = await patch("update_model_name/" + selectedModel, body);
      toast.success(response.msg);
      loadModels();
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al editar el nombre del modelo.");
      }
    }
  };

  const deleteModel = async () => {
    try {
      const response = await del("delete_model/" + selectedModel);
      toast.success(response.msg);
      loadModels();
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al eliminar el modelo.");
      }
    }
  };

  const handleClose = () => {
    setOpenEdit(false);
    setOpenDelete(false);
    setNameChange("");
  };

  return (
    <PageContainerDashboard>
      <EditNameModal
        open={openEdit}
        onClose={handleClose}
        name={nameChange}
        namesList={models}
        setName={setNameChange}
        handleSaveName={editModelName}
      />
      <DeleteModelModal
        open={openDelete}
        onClose={handleClose}
        handleSaveDelete={deleteModel}
      />
      <Grid
        container
        spacing={3}
        sx={{ p: "20px", alignContent: "flex-start" }}
      >
        <Grid item xs={12}>
          <FlexColumn gap="8px">
            <Typography variant="h5">Modelos</Typography>
            <Typography variant="body1">
              En esta sección puedes visualizar todos los modelos que has
              cargado hasta hoy.
            </Typography>
            <StyledDivider />
          </FlexColumn>
        </Grid>
        <Grid item xs={12}>
          <StyledButton
            variant="contained"
            onClick={() => handleNavigation("/dashboard/load-data")}
          >
            <Typography
              variant="h5"
              sx={{
                lineHeight: "100%",
                marginBottom: "4px",
                fontWeight: "500",
                color: "#EBFDEA",
              }}
            >
              Carga un nuevo modelo
            </Typography>
          </StyledButton>
        </Grid>
        <Grid item xs={12}>
          <BorderCard>
            <FlexColumn gap="8px" padding="16px">
              <Typography variant="h4">Historial de modelos</Typography>
              <FlexRow gap="8px">
                <StyledButton
                  variant="outlined"
                  secondary
                  sx={{
                    backgroundColor:
                      activeFilter === "recientes" ? "#EBFDEA" : "transparent",
                  }}
                  onClick={() => handleFilterClick("recientes")}
                >
                  <Typography variant="h6">Recientes</Typography>
                </StyledButton>
                <StyledButton
                  variant="outlined"
                  secondary
                  sx={{
                    backgroundColor:
                      activeFilter === "favoritos" ? "#EBFDEA" : "transparent",
                  }}
                  onClick={() => handleFilterClick("favoritos")}
                >
                  <Typography variant="h6">Favoritos</Typography>
                </StyledButton>
              </FlexRow>
              <FlexRow gap="8px" alignItems="center">
                <CustomTextField
                  label="Buscar"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    endAdornment: <SearchIcon sx={{ color: "#02403C" }} />,
                  }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <CustomDatePicker
                      label="Desde"
                      size="small"
                      value={startDate ? dayjs(startDate) : null}
                      onChange={handleStartDateChange}
                      renderInput={(params) => (
                        <CustomTextField {...params} fullWidth />
                      )}
                    />
                  </DemoContainer>
                  <DemoContainer components={["DatePicker"]}>
                    <CustomDatePicker
                      label="Hasta"
                      size="small"
                      value={endDate ? dayjs(endDate) : null}
                      onChange={handleEndDateChange}
                      renderInput={(params) => (
                        <CustomTextField {...params} fullWidth />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </FlexRow>
              <StyledDivider sx={{ marginBottom: "20px" }} />
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <Typography variant="h6">Favoritos</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h6">Nombre modelo</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h6">Fecha de creación</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h6">Fase de auditoría</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h6">
                          Usado para predicción
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h6">Editar</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h6">Eliminar</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {filteredData.length === 0 ? (
                    <Typography>No se encontraron resultados.</Typography>
                  ) : (
                    <TableBody>
                      {filteredData.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                          onClick={() => handleSelectModel(row.id_model)}
                        >
                          <TableCell align="center">
                            <Typography variant="body1">
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  addModelFavorites(
                                    row.id_model,
                                    !row.favorite
                                  );
                                }}
                              >
                                {row.favorite ? (
                                  <GradeIcon />
                                ) : (
                                  <StarOutlineIcon />
                                )}
                              </Button>
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body1">{row.name}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body1">
                              {row.creation_date}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body1">{row.phase}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body1">
                              {row.prediction ? "Usado" : "No usado"}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body1">
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setSelectedModel(row.id_model);
                                  setOpenEdit(true);
                                }}
                              >
                                <EditIcon />
                              </Button>
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body1">
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setSelectedModel(row.id_model);
                                  setOpenDelete(true);
                                }}
                              >
                                <DeleteIcon />
                              </Button>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </FlexColumn>
          </BorderCard>
        </Grid>
      </Grid>
    </PageContainerDashboard>
  );
};

export default Dashboard;

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Slide,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";
import StyledButton from "../../common/button/StyledButton";
import { logoCriteria } from "../../../images/Index";
import FlexRow from "../../shared/container/FlexRow";
import FlexColumn from "../../shared/container/FlexColumn";
import StyledDottedDivider from "../divider/StyledDottedDivider";
import { useNavigate } from "react-router-dom";
import IconFooterList from "../../icons/IconFooterList";

const CustomNavLink = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  zIndex: "999",
  width: "100%",
}));

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  position: "relative",
  background: theme.palette.background.default,
  color: theme.palette.primary.main,
  borderBottom: "1px solid" + theme.palette.primary.main,
}));

const CustomContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1560px",
  margin: "0 auto",
  padding: "0px 80px",
}));

const CustomSlide = styled(Slide)(({ theme }) => ({
  background: "#FFFFFF",
  position: "relative",
  zIndex: "-1",
  width: "100%",
  border: "1px solid " + theme.palette.primary.main,
  borderRadius: "0px 0px 60px 60px",
  overflow: "hidden",
}));

const CustomListItem = styled(ListItem)(({ theme, ...props }) => ({
  padding: "0px",
}));

const CustomListItemIcon = styled(ListItemIcon)(({ theme, ...props }) => ({
  minWidth: "unset",
  marginRight: "8px",
}));

const StyledAppBar = (props) => {
  const [openSolution, setOpenSolution] = useState(false);
  const [openResources, setOpenResources] = useState(false);
  const navigate = useNavigate();

  const handleOpenSolution = () => {
    setOpenResources(false);
    setOpenSolution(!openSolution);
  };

  const handleOpenResources = () => {
    setOpenSolution(false);
    setOpenResources(!openResources);
  };

  const handleNavigation = (path, hash = "") => {
    navigate(path);
    window.scrollTo(0, 0);
    setOpenSolution(false);
    setOpenResources(false);
    if (hash) {
      // Usa un timeout para asegurar que la página se haya cargado antes de intentar el scroll
      setTimeout(() => {
        const element = document.getElementById(hash.substring(1)); // Elimina el "#" del hash
        if (element) {
          const elementPosition =
            element.getBoundingClientRect().top + window.scrollY;
          const offset = 160;
          window.scrollTo({
            top: elementPosition - offset,
            behavior: "smooth",
          });
        }
      }, 200); // Ajusta el tiempo según sea necesario
    }
  };

  return (
    <CustomBox>
      <CustomAppBar {...props}>
        <CustomContainer>
          <Toolbar sx={{ padding: "28px 0px !important" }}>
            <FlexRow
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box sx={{ cursor: "pointer" }}>
                <img
                  src={logoCriteria}
                  alt="logo"
                  onClick={() => handleNavigation("/")}
                />
              </Box>

              <FlexRow gap="20px" justifyContent="center" alignContent="center">
                <CustomNavLink
                  variant="h5"
                  onClick={() => handleNavigation("/about-us")}
                >
                  IA Responsable
                </CustomNavLink>
                <CustomNavLink
                  variant="h5"
                  onClick={() => handleOpenSolution()}
                >
                  Soluciones
                </CustomNavLink>
                <CustomNavLink
                  variant="h5"
                  onClick={() => handleOpenResources()}
                >
                  Recursos
                </CustomNavLink>
                <Box
                  sx={{ height: "36px", width: "1px", background: "#02403C" }}
                ></Box>
                <StyledButton
                  variant="outlined"
                  secondary="true"
                  sx={{ height: "fit-content" }}
                  onClick={() => {
                    const element = document.getElementById("Contact");
                    if (element) {
                      const elementPosition =
                        element.getBoundingClientRect().top + window.scrollY;
                      const offset = 160;
                      window.scrollTo({
                        top: elementPosition - offset,
                        behavior: "smooth",
                      });
                    } else {
                      handleNavigation("/", "#Contact");
                    }
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      lineHeight: "100%",
                      marginBottom: "4px",
                      fontWeight: "600",
                      fontSize: "20px",
                    }}
                  >
                    Contacto
                  </Typography>
                </StyledButton>
                <StyledButton
                  variant="contained"
                  sx={{ height: "fit-content" }}
                  onClick={() => handleNavigation("/login")}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      lineHeight: "100%",
                      marginBottom: "4px",
                      fontWeight: "500",
                      fontSize: "20px",
                    }}
                  >
                    Ingresar
                  </Typography>
                </StyledButton>
              </FlexRow>
            </FlexRow>

            {props.children}
          </Toolbar>
        </CustomContainer>
      </CustomAppBar>
      <CustomSlide
        direction="down"
        in={openSolution}
        mountOnEnter
        unmountOnExit
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <FlexRow>
            <FlexColumn
              padding="50px"
              sx={{ width: "100%", background: "#EFFAED" }}
            >
              <Typography variant="h6">
                Software en la nube para evaluar y potenciar la equidad de
                algoritmos
              </Typography>
              <StyledDottedDivider sx={{ margin: "16px 0px" }} />
              <FlexRow sx={{ height: "100%" }}>
                <FlexColumn sx={{ width: "30%" }}>
                  <Typography variant="h6">Modulos</Typography>
                  <List>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Rendimiento técnico
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Detección de Sesgos
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Mitigación de Sesgos
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                  </List>
                </FlexColumn>
                <StyledDottedDivider vertical sx={{ margin: "0px 42px" }} />
                <FlexColumn sx={{ width: "30%" }}>
                  <Typography variant="h6">Casos de uso</Typography>
                  <List>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Industria financiera
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Gobierno
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Hospitales
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                  </List>
                </FlexColumn>
                <StyledDottedDivider vertical sx={{ margin: "0px 42px" }} />
                <FlexColumn sx={{ width: "30%" }}>
                  <Typography variant="h6">
                    Características y Beneficios
                  </Typography>
                  <List>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Característica 1
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Característica 1
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Característica 1
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                  </List>
                </FlexColumn>
                <StyledDottedDivider vertical sx={{ margin: "0px 42px" }} />
                <FlexColumn
                  gap="20px"
                  alignItems="flex-start"
                  sx={{ width: "70%" }}
                >
                  <Typography variant="body1">
                    Evalúa y potencia la equidad de los algoritmos que impactan
                    a tus usuarios en nuestro cloud software{" "}
                  </Typography>
                  <StyledButton
                    variant="outlined"
                    secondary
                    onClick={() => handleNavigation("/solution-saas")}
                  >
                    Ver más
                  </StyledButton>
                  <StyledButton
                    variant="contained"
                    onClick={() => {
                      const element = document.getElementById("Prices");
                      if (element) {
                        const elementPosition =
                          element.getBoundingClientRect().top + window.scrollY;
                        const offset = 160;
                        window.scrollTo({
                          top: elementPosition - offset,
                          behavior: "smooth",
                        });
                      } else {
                        handleNavigation("/solution-saas", "#Prices");
                      }
                    }}
                  >
                    Ver precios
                  </StyledButton>
                </FlexColumn>
              </FlexRow>
            </FlexColumn>
          </FlexRow>
        </Box>
      </CustomSlide>
      <CustomSlide
        direction="down"
        in={openResources}
        mountOnEnter
        unmountOnExit
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <FlexRow>
            <FlexColumn padding="50px" sx={{ width: "60%" }}>
              <Typography variant="h6">
                Navega con nosotros en el mundo de la inteligencia artificial
                responsable
              </Typography>
              <StyledDottedDivider sx={{ margin: "16px 0px" }} />
              <FlexRow sx={{ height: "100%" }}>
                <FlexColumn sx={{ width: "30%" }}>
                  <List>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">Videos</CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem
                      component={Link}
                      underline="none"
                      onClick={() => handleNavigation("/resources")}
                    >
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">Blogs</CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Casos de Estudio
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Webinars
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">Guías</CustomNavLink>
                        }
                      />
                    </CustomListItem>
                  </List>
                </FlexColumn>
                <StyledDottedDivider vertical sx={{ margin: "0px 42px" }} />
                <FlexColumn sx={{ width: "30%" }}>
                  <List>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">Soporte</CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Publicaciones
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Noticias
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">Prensa</CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">FAQs</CustomNavLink>
                        }
                      />
                    </CustomListItem>
                  </List>
                </FlexColumn>
                <StyledDottedDivider vertical sx={{ margin: "0px 42px" }} />
                <FlexColumn sx={{ width: "30%" }}>
                  <List>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Educación
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Tutoriales
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Seminarios y Webinars
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Foro Comunidad
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                    <CustomListItem component={Link} underline="none">
                      <CustomListItemIcon>
                        <IconFooterList green />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomNavLink variant="body1">
                            Alianzas
                          </CustomNavLink>
                        }
                      />
                    </CustomListItem>
                  </List>
                </FlexColumn>
              </FlexRow>
            </FlexColumn>
            <FlexColumn
              padding="50px"
              sx={{ width: "40%", background: "#02403C" }}
            >
              <Typography variant="h6" color="#EFFAED">
                Contenido reciente
              </Typography>
              <StyledDottedDivider secondary sx={{ margin: "16px 0px" }} />
              <FlexRow sx={{ height: "100%" }}>
                <FlexColumn sx={{ width: "40%" }}></FlexColumn>
                <StyledDottedDivider
                  secondary
                  vertical
                  sx={{ margin: "0px 42px" }}
                />
                <FlexColumn
                  gap="20px"
                  alignItems="flex-start"
                  sx={{ width: "60%" }}
                >
                  <Typography variant="body1" color="#EFFAED">
                    Descripción corta del contenido
                  </Typography>
                  <StyledButton
                    variant="contained"
                    sx={{ background: "#EEFBED !important", color: "#02403C" }}
                    onClick={() => handleNavigation("/resources")}
                  >
                    Ver más
                  </StyledButton>
                </FlexColumn>
              </FlexRow>
            </FlexColumn>
          </FlexRow>
        </Box>
      </CustomSlide>
    </CustomBox>
  );
};

export default StyledAppBar;

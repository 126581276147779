import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import ReactECharts from "echarts-for-react";
import { DataContext } from "../../context/DataContext";
import {
  PageContainerDashboard,
  BorderCard,
  FlexColumn,
  FlexRow,
} from "../../components/shared/container/Index";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Slider,
  Typography,
} from "@mui/material";
import StyledDivider from "../../components/common/divider/StyledDivider";
import StyledButton from "../../components/common/button/StyledButton";
import MitigationReport from "../../components/shared/reports/MitigationPDFReport";
import StyledSkeletonRectangular from "../../components/common/skeleton/StyledSkeletonRectangular";
import { EchartsContext } from "../../context/EchartsContext";
import { useApiClient } from "../../api/apiService";
import { toast } from "react-toastify";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const Mitigation = () => {
  const { post } = useApiClient();
  const navigate = useNavigate();
  const { addChart, deleteChart } = useContext(EchartsContext);
  const { actualData, addMitigationData } = useContext(DataContext);
  const [mitigationVars, setMitigationVars] = useState([]);
  const [checkedMetrics, setCheckedMetrics] = useState([]);
  const [showMore, setShowMore] = useState({});

  const [showMoredpca, setShowMoredpca] = useState(true);

  const toogleShowMoredpca = () => {
    setShowMoredpca(!showMoredpca);
  };

  const [showGraphics, setShowGraphics] = useState(false);
  const [loading, setLoading] = useState(true);

  const [dpCaText, setDpCaText] = useState(null);

  const [distributionVariables, setDistributionVariables] = useState(null);
  const [generalMetricsVariables, setGeneralMetricsVariables] = useState(null);
  const [poblationVariable, setPoblationVariables] = useState(null);

  const distributionVariableChartRefs = useRef([]);
  const distributionvariableCharts = useRef(false);
  const generalMetricsVariableChartRefs = useRef([]);
  const generalMetricsvariableCharts = useRef(false);
  const poblationVariableChartRefs = useRef([]);
  const poblationVariableCharts = useRef(false);

  const [generalProtectedVariablesFpr, setGeneralProtectedVariablesFpr] =
    useState(null);
  const [generalProtectedVariablesTpr, setGeneralProtectedVariablesTpr] =
    useState(null);

  const [generalProtectedVariablesEo, setGeneralProtectedVariablesEo] =
    useState(null);

  const [generalProtectedVariablesAntes, setGeneralProtectedVariablesAntes] =
    useState(null);
  const [generalProtectedVariablesAhora, setGeneralProtectedVariablesAhora] =
    useState(null);

  const generalProtectedVariableFprChartRefs = useRef(null);
  const generalProtectedvariableFprCharts = useRef(false);
  const generalProtectedVariableTprChartRefs = useRef(null);
  const generalProtectedvariableTprCharts = useRef(false);

  const generalProtectedVariableEoChartRefs = useRef([]);
  const generalProtectedvariableEoCharts = useRef(false);

  const generalProtectedVariableAntesChartRefs = useRef([]);
  const generalProtectedvariableAntesCharts = useRef(false);
  const generalProtectedVariableAhoraChartRefs = useRef([]);
  const generalProtectedvariableAhoraCharts = useRef(false);

  const downloadMitigatedDataframe = (data) => {
    try {
      if (!data) {
        console.error("No data available for download");
        return;
      }

      // Crear un array de objetos para cada fila
      const rows = [];
      const keys = Object.keys(data);

      // Determinar el número máximo de filas
      const maxRows = Math.max(...keys.map((key) => data[key].length));

      // Crear filas combinando los datos por índice
      for (let i = 0; i < maxRows; i++) {
        const row = {};
        keys.forEach((key) => {
          const value = data[key][i];
          row[key] = value !== undefined && value !== null ? value : ""; // Solo reemplazar si es undefined o null
        });
        rows.push(row);
      }

      // Crear el workbook de Excel
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(rows);

      // Agregar la hoja al workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Mitigated Data");

      // Generar el archivo Excel como un Blob
      const excelData = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Crear el Blob y descargarlo
      const blob = new Blob([excelData], { type: "application/octet-stream" });
      saveAs(blob, "Predicciones_mitigadas_" + actualData.name + ".xlsx");
    } catch (error) {
      console.error("Error while downloading the Excel file:", error);
    }
  };

  const exportChartsAsImages = useCallback(
    (chartRefs, contextKey) => {
      const images = chartRefs.current
        .map((chartRef) => {
          if (chartRef) {
            const echartInstance = chartRef.getEchartsInstance();
            const currentOptions = echartInstance.getOption();

            // Desactivar la animación
            echartInstance.setOption({ animation: false });

            // Generar la imagen con la animación desactivada
            const imgData = echartInstance.getDataURL({
              type: "png",
              pixelRatio: 4,
              backgroundColor: "#FFF",
            });

            // Restaurar la animación
            echartInstance.setOption({ animation: currentOptions.animation });

            return imgData;
          }
          return null;
        })
        .filter((imgData) => imgData !== null);
      addChart(contextKey, images);
    },
    [addChart]
  );

  const exportAllCharts = useCallback(() => {
    // Exportación de gráficos de distribución
    exportChartsAsImages(
      distributionVariableChartRefs,
      "distributionMitigationCharts"
    );

    // Exportación de gráficos de métricas generales
    exportChartsAsImages(
      generalMetricsVariableChartRefs,
      "generalMetricsMitigationCharts"
    );

    // Exportación de gráficos de variables poblacionales
    exportChartsAsImages(
      poblationVariableChartRefs,
      "poblationMitigationCharts"
    );

    // Exportación de gráficos FPR
    if (generalProtectedVariableFprChartRefs.current) {
      exportChartsAsImages(
        { current: [generalProtectedVariableFprChartRefs.current] },
        "fprMitigationCharts"
      );
    }

    // Exportación de gráficos TPR
    if (generalProtectedVariableTprChartRefs.current) {
      exportChartsAsImages(
        { current: [generalProtectedVariableTprChartRefs.current] },
        "tprMitigationCharts"
      );
    }

    // Exportación de gráficos EO
    exportChartsAsImages(
      generalProtectedVariableEoChartRefs,
      "eoMitigationCharts"
    );

    // Exportación de gráficos Antes y Ahora
    exportChartsAsImages(
      generalProtectedVariableAntesChartRefs,
      "antesMitigationCharts"
    );
    exportChartsAsImages(
      generalProtectedVariableAhoraChartRefs,
      "ahoraMitigationCharts"
    );
  }, [
    exportChartsAsImages,
    distributionVariableChartRefs,
    generalMetricsVariableChartRefs,
    poblationVariableChartRefs,
    generalProtectedVariableFprChartRefs,
    generalProtectedVariableTprChartRefs,
    generalProtectedVariableEoChartRefs,
    generalProtectedVariableAntesChartRefs,
    generalProtectedVariableAhoraChartRefs,
  ]);

  // Efecto que se ejecuta cuando el estado `loading` cambia a `false`
  useEffect(() => {
    if (!loading) {
      exportAllCharts();
    }
  }, [loading]);

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (actualData && actualData.metrics_dataframe) {
      const barrasMetricasKeys = new Set([
        ...mitigationVars,
        ...actualData.metrics_dataframe.attribute_name,
      ]);
      setMitigationVars(Array.from(barrasMetricasKeys));
      setCheckedMetrics([actualData.biased_variables.sugerida]);
    }
  }, [actualData]);

  const handleCheck = (variable) => {
    setCheckedMetrics((prev) => {
      if (prev.includes(variable)) {
        return [];
      } else {
        return [variable];
      }
    });
  };

  const handleToggleShowMore = (key) => {
    setShowMore((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleMitigate = async () => {
    deleteChart("distributionMitigationCharts");
    deleteChart("generalMetricsMitigationCharts");
    deleteChart("poblationMitigationCharts");
    deleteChart("fprMitigationCharts");
    deleteChart("tprMitigationCharts");
    deleteChart("eoMitigationCharts");
    deleteChart("antesMitigationCharts");
    deleteChart("ahoraMitigationCharts");

    const body = {
      dataframe: actualData.intersect_dataframe,
      variable: checkedMetrics[0],
      fairness_definition: actualData.fairness_definition,
      df_metrics: actualData.metrics_dataframe,
      id_model: actualData.id_model,
    };
    try {
      const response = await post("mitigate/", body);
      handleResponse(response.data);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.msg}`);
      } else {
        toast.error(
          "Error: Ocurrió un error al cargar la información de las gráficas."
        );
      }
    }
  };

  const handleResponse = (response) => {
    const mitigated_dataframe = response.mitigated;
    const mitigated_metrics_dataframe = response.metrics_mitigated;
    const mitigate_variable = checkedMetrics[0];
    addMitigationData(
      mitigated_dataframe,
      mitigated_metrics_dataframe,
      mitigate_variable
    );
    setDistributionVariables(response.graphics?.distribution || null);
    setGeneralMetricsVariables(response.graphics?.general_metrics || null);
    setGeneralProtectedVariablesFpr(
      response.graphics?.metrics_protected_variable_fpr || null
    );
    setGeneralProtectedVariablesTpr(
      response.graphics?.metrics_protected_variable_tpr || null
    );
    setGeneralProtectedVariablesEo(
      response.graphics?.metrics_protected_variable_eo || null
    );

    setDpCaText(response.graphics.text || null);
    setGeneralProtectedVariablesAntes(response.graphics.antes || null);
    setGeneralProtectedVariablesAhora(response.graphics.ahora || null);
    setPoblationVariables(response.graphics.grupo_poblacional || null);
    setLoading(false);
  };

  const mitigate = () => {
    setShowGraphics(true);
    handleMitigate();
  };

  const generateDistributionOption = (variableData, key) => {
    return {
      tooltip: {
        trigger: "axis",
      },
      title: {
        text: `Cambio en el valor predicho \n ${key}`,
        left: "center",
      },
      xAxis: {
        type: "category",
        data: variableData.x_axis,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: `Serie ${variableData.series[0]}`,
          data: variableData.data[0],
          type: "bar",
          stack: "total",
          itemStyle: {
            color: "#02403C",
          },
          label: {
            show: true,
            position: "inside",
            formatter: (params) => {
              const percentage =
                variableData.percentage[0][params.dataIndex] * 100;
              return `${percentage.toFixed(0)}%`;
            },
          },
        },
        {
          name: `Serie ${variableData.series[1]}`,
          data: variableData.data[1],
          type: "bar",
          stack: "total",
          itemStyle: {
            color: "#028F8C",
          },
          label: {
            show: true,
            position: "inside",
            formatter: (params) => {
              const percentage =
                variableData.percentage[1][params.dataIndex] * 100; // Multiplicar por 100 para convertir a porcentaje
              return `${percentage.toFixed(0)}%`; // Mostrar sin decimales
            },
          },
        },
      ],
    };
  };

  const generateGeneralMetricsOption = (variableData, key) => {
    let title = "";

    if (key === "accuracy") {
      title = `cambio \n Accuracy`;
    } else if (key === "true_positive_rate") {
      title = `cambio \n True positive rate`;
    } else if (key === "false_positive_rate") {
      title = `cambio \n False positive rate`;
    } else {
      title = `cambio \n F1 score`;
    }

    return {
      tooltip: {
        trigger: "axis",
      },
      title: {
        text: `${title}`,
        left: "center",
      },
      xAxis: {
        type: "category",
        data: variableData.x_axis,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: key,
          data: variableData.data,
          type: "bar",
          itemStyle: {
            color: "#A6819F",
          },
        },
      ],
    };
  };

  const generateGeneralProtectedOption = (variableData, title) => {
    // Insertar una categoría vacía entre "Antes" y "Después"
    const beforeCount = variableData.xAxis.filter((x) =>
      x.includes("(Antes)")
    ).length;
    const newXAxis = [
      ...variableData.xAxis.slice(0, beforeCount),
      " ", // Categoría vacía para la división
      ...variableData.xAxis.slice(beforeCount),
    ];

    const newXAxis2 = newXAxis.map((axis) => axis.split(" (")[0]);

    // Insertar un valor 0 en data para la categoría vacía
    const newData = [
      ...variableData.data.slice(0, beforeCount),
      0, // Valor 0 para la categoría vacía
      ...variableData.data.slice(beforeCount),
    ];

    const newColorData = [
      ...variableData.color_data.slice(0, beforeCount),
      "#ffffff", // Color blanco (invisible) para la barra de la categoría vacía
      ...variableData.color_data.slice(beforeCount),
    ];

    return {
      tooltip: {
        trigger: "axis",
        formatter: (params) => {
          // Filtra el tooltip para no mostrar la categoría vacía
          const filteredParams = params.filter(
            (item) => item.axisValue !== " " && item.value !== 0
          );
          // Si no hay datos válidos, no mostrar tooltip
          if (filteredParams.length === 0) return "";
          // Formatea el tooltip normalmente para los datos restantes
          return filteredParams
            .map(
              (item) =>
                `${item.marker} ${item.axisValue}: ${item.value.toFixed(2)}`
            )
            .join("<br>");
        },
      },
      title: {
        text: title,
        left: "center",
      },
      xAxis: {
        type: "category",
        data: newXAxis2,
        axisLabel: {
          interval: 0,
          rotate: 30,
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: newData.map((value, index) => ({
            value,
            itemStyle: {
              color: newColorData[index],
            },
          })),
          type: "bar",
          markLine: {
            symbol: "none",
            data: [
              {
                xAxis: " ",
                lineStyle: {
                  type: "dashed",
                  color: "#333",
                },
                label: {
                  show: true,
                  position: "end",
                  formatter: "",
                },
              },
            ],
          },
        },
      ],
      graphic: [
        {
          type: "text",
          left: "15%",
          top: "12%",
          style: {
            text: "Antes",
            fontSize: 14,
            fontWeight: "bold",
            fill: "#02403C",
          },
        },
        {
          type: "text",
          left: "55%",
          top: "12%",
          style: {
            text: "Ahora",
            fontSize: 14,
            fontWeight: "bold",
            fill: "#02403C",
          },
        },
      ],
    };
  };

  const generateCompleteCurveOption = (variableData, title) => {
    const series = [];
    const legendData = []; // Almacenará los nombres que aparecerán en la leyenda

    Object.keys(variableData.data.xAxis).forEach((key) => {
      if (key !== "diag") {
        legendData.push(key); // Solo agregar a la leyenda si no es 'diag'
      }

      series.push({
        name: key,
        data: variableData.data.xAxis[key].map((x, index) => [
          x,
          variableData.data.data[key][index],
        ]),
        type: "line",
        smooth: title === "Distribución de \n probabilidades por grupo",
        symbol: "none",
        tooltip: key === "diag" ? { show: false } : {}, // Ocultar tooltip si la serie es 'diag'
        lineStyle: key === "diag" ? { color: "black", type: "dotted" } : {}, // Color negro y línea punteada para 'diag'
      });
    });

    return {
      tooltip: {
        trigger: "axis",
      },
      title: {
        text: title,
        left: "center",
      },
      legend: {
        type: "scroll",
        orient: "horizontal",
        left: "center",
        top: "bottom",
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 11,
        },
        data: legendData, // Solo mostrar series que no son 'diag'
      },
      grid: {
        top: 80,
        left: 50,
        bottom: 80,
      },
      xAxis: {
        name:
          title === "Distribución de \n probabilidades por grupo"
            ? "Valor Predicho"
            : "Predicción",
        nameLocation: "center",
        nameGap: 0,
        nameTextStyle: {
          align: "center",
          verticalAlign: "top",
          padding: [30, 0, 0, 0],
        },
        type: "value",
      },
      yAxis: {
        name:
          title === "Distribución de \n probabilidades por grupo"
            ? ""
            : "Proporción de positivos",
        nameLocation: "center",
        nameGap: 30,
        nameTextStyle: {
          align: "center",
          padding: [0, 0, 0, 0],
        },
        type: "value",
      },
      series: series,
    };
  };

  const generateCurveOption = (variableData, title) => {
    return {
      tooltip: {
        trigger: "axis",
      },
      title: {
        text: title,
        left: "center",
      },
      grid: {
        top: 80,
        left: 50,
        bottom: 80,
      },
      xAxis: {
        name:
          title === "Calibración"
            ? "Predicción"
            : title === "ROC"
            ? "FPR"
            : "Recall",
        nameLocation: "center",
        nameGap: 0,
        nameTextStyle: {
          align: "center",
          verticalAlign: "top",
          padding: [30, 0, 0, 0],
        },
        type: "value",
      },
      yAxis: {
        name:
          title === "Calibración"
            ? "Proporción de positivos"
            : title === "ROC"
            ? "TPR"
            : "Precisión",
        nameLocation: "center",
        nameGap: 30,
        nameTextStyle: {
          align: "center",
          padding: [0, 0, 0, 0],
        },
        type: "value",
      },
      series: variableData.data.y_s.map((ys, index) => ({
        data: variableData.data.xAxis[index].data.map((x, idx) => [
          x,
          ys.data[idx],
        ]),
        type: "line",
        showSymbol: false,
        lineStyle: {
          type: index === 0 ? "dotted" : "solid",
          color: index === 0 ? "black" : "#9FC7D0",
        },
        tooltip: {
          show: index !== 0,
        },
        itemStyle: {
          color: "#9FC7D0",
        },
        ...(index !== 0 && title !== "Calibración" ? { areaStyle: {} } : {}),
      })),
    };
  };

  const generateCaHistogramaOption = (histogramaData, key) => {
    const series = [];

    const createBarSeries = (data, name) => ({
      name: name,
      type: "bar",
      data: data.filter((value) => value !== "-"),
      itemStyle: {
        color: name.includes("Antes") ? "#A6819F" : "#02403C",
      },
      barWidth: "100%",
    });

    // Series de datos "Antes"
    const antesData = histogramaData.ANTES;
    series.push(createBarSeries(antesData, "Antes"));

    // Series de datos "Ahora"
    const ahoraData = histogramaData.AHORA;
    series.push(createBarSeries(ahoraData, "Ahora"));

    // Serie de línea para marcar la separación
    const separationLine = {
      type: "line",
      tooltip: {
        show: false,
      },
      markLine: {
        symbol: "none",
        data: [
          {
            xAxis: antesData.length,
            label: {
              show: true,
              position: "end",
              formatter: "",
            },
          },
        ],
        lineStyle: {
          color: "#000",
          type: "dashed",
          width: 1.5,
        },
      },
    };
    series.push(separationLine);

    // Verificar si el número de barras excede 20
    const totalBars = Math.max(antesData.length, ahoraData.length);

    return {
      title: {
        text: `cambio de distribución \n de probabilidades ${key}`,
        left: "center",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        top: 60,
        left: 50,
      },
      xAxis: {
        name: "Predicción",
        nameLocation: "center",
        nameGap: 0,
        nameTextStyle: {
          align: "center",
          verticalAlign: "top",
          padding: [30, 0, 0, 0],
        },
        type: "category",
        axisLabel: {
          show: totalBars <= 14, // Mostrar etiquetas solo si las barras son <= 20
          interval: 0,
          rotate: 45,
          formatter: (value) => parseFloat(value).toFixed(2),
          fontSize: 10,
        },
      },
      yAxis: {
        name: "Observaciones",
        nameLocation: "center",
        nameGap: 30,
        nameTextStyle: {
          align: "center",
          padding: [0, 0, 0, 0],
        },
        type: "value",
      },
      series: series,
      graphic: [
        {
          type: "text",
          left: "15%",
          top: "13%",
          style: {
            text: "Antes",
            fontSize: 14,
            fontWeight: "bold",
            fill: "#A6819F",
          },
        },
        {
          type: "text",
          left: "65%",
          top: "13%",
          style: {
            text: "Ahora",
            fontSize: 14,
            fontWeight: "bold",
            fill: "#02403C",
          },
        },
      ],
    };
  };

  const generateCaLineasOption = (lineasData, key) => {
    const series = [];

    // Función para crear cada serie con estilos personalizados
    const createSeries = (data, name) => ({
      name: name,
      type: "line",
      data: data,
      lineStyle: {
        color: name.includes("Antes") ? "#A6819F" : "#02403C",
      },
      itemStyle: {
        color: name.includes("Antes") ? "#A6819F" : "#02403C",
      },
    });

    // Generar series para "ANTES"
    lineasData.ANTES.y_s.forEach((line, index) => {
      const xAxisData = lineasData.ANTES.xAxis[index]?.data || []; // Obtiene el correspondiente xAxis para la serie
      const yData = line.data;

      // Emparejar x e y basados en el índice para la serie
      const combinedData = xAxisData.map((xValue, idx) => [xValue, yData[idx]]);
      series.push(createSeries(combinedData, `Antes - Línea ${index + 1}`));
    });

    // Generar series para "AHORA"
    lineasData.AHORA.y_s.forEach((line, index) => {
      const xAxisData = lineasData.AHORA.xAxis[index]?.data || []; // Obtiene el correspondiente xAxis para la serie
      const yData = line.data;

      // Emparejar x e y basados en el índice para la serie
      const combinedData = xAxisData.map((xValue, idx) => [xValue, yData[idx]]);
      series.push(createSeries(combinedData, `Ahora - Línea ${index + 1}`));
    });

    return {
      title: {
        text: `Cambio en curva \n de calibración ${key}`,
        left: "center",
      },
      tooltip: {
        trigger: "axis",
      },
      grid: {
        top: 60,
        left: 50,
      },
      xAxis: {
        name: "Predicción",
        nameLocation: "center",
        nameGap: 0,
        nameTextStyle: {
          align: "center",
          verticalAlign: "top",
          padding: [30, 0, 0, 0],
        },
        type: "value",
      },
      yAxis: {
        name: "Proporción de positivos",
        nameLocation: "center",
        nameGap: 30,
        nameTextStyle: {
          align: "center",
          padding: [0, 0, 0, 0],
        },
        type: "value",
      },
      series: series,
    };
  };

  const generateDpOption = (graphicsData, key) => {
    const antesSeries = graphicsData.ANTES.xAxis.map((x, index) => [
      x,
      graphicsData.ANTES.data[index],
    ]);
    const ahoraSeries = graphicsData.AHORA.xAxis.map((x, index) => [
      x,
      graphicsData.AHORA.data[index],
    ]);

    return {
      title: {
        text: `cambio de distribución \n de probabilidades ${key}`,
        left: "center",
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["Antes", "Ahora"],
        top: "44px",
        left: "16px",
      },
      xAxis: {
        name: "Valor predicho",
        nameLocation: "center",
        nameGap: 0,
        nameTextStyle: {
          align: "center",
          verticalAlign: "top",
          padding: [30, 0, 0, 0],
        },
        type: "value",
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Antes",
          type: "line",
          showSymbol: false,
          data: antesSeries,
          lineStyle: {
            color: "#A6819F",
          },
          itemStyle: {
            color: "#A6819F",
          },
        },
        {
          name: "Ahora",
          type: "line",
          showSymbol: false,
          data: ahoraSeries,
          lineStyle: {
            color: "#809F9E",
          },
          itemStyle: {
            color: "#809F9E",
          },
        },
      ],
    };
  };

  const MetricsBox = (props) => {
    const { data, state } = props;
    const [umbral, setUmbral] = useState(0.5);
    const [metrics, setMetrics] = useState(null);

    useEffect(() => {
      if (!loading) {
        const updatedMetrics = data.valores_completos[umbral.toFixed(2)];
        if (updatedMetrics) {
          setMetrics(updatedMetrics);
        }
      }
    }, [loading, umbral]);

    return (
      <BorderCard padding="20px">
        <FlexColumn gap="8px">
          <Typography variant="h6" textAlign="center">
            {state === "antes" ? "Modelo inicial" : "Modelo mitigado"}
          </Typography>
          <FlexRow gap="20px" justifycontent="center">
            <FlexRow gap="6px">
              <Typography variant="body1" textAlign="center">
                AUC:
              </Typography>
              <Typography variant="body2" textAlign="center">
                {data.cajitas_metricas_fijas.data.AUC}
              </Typography>
            </FlexRow>
            <FlexRow gap="6px">
              <Typography variant="body1" textAlign="center">
                AUCPR:
              </Typography>
              <Typography variant="body2" textAlign="center">
                {data.cajitas_metricas_fijas.data.AUCPR}
              </Typography>
            </FlexRow>
            <FlexRow gap="6px">
              <Typography variant="body1" textAlign="center">
                BRIER:
              </Typography>
              <Typography variant="body2" textAlign="center">
                {data.cajitas_metricas_fijas.data.Brier_Score}
              </Typography>
            </FlexRow>
          </FlexRow>

          <Grid
            container
            spacing={2}
            columns={{
              xs: 4,
              sm: 4,
              md: 12,
              xl: 12,
              alignContent: "flex-start",
            }}
          >
            <Grid item xs={4} sm={4} md={12} xl={6}>
              {data.curvas_completas_ca && (
                <ReactECharts
                  ref={
                    state === "antes"
                      ? (el) =>
                          (generalProtectedVariableAntesChartRefs.current[0] =
                            el)
                      : (el) =>
                          (generalProtectedVariableAhoraChartRefs.current[0] =
                            el)
                  }
                  option={generateCompleteCurveOption(
                    data.curvas_completas_ca,
                    "Curvas de calibración \n por grupo"
                  )}
                  style={{ height: "360px", width: "100%", maxWidth: "440px" }}
                />
              )}
              {data.curvas_completas_dp && (
                <ReactECharts
                  ref={
                    state === "antes"
                      ? (el) =>
                          (generalProtectedVariableAntesChartRefs.current[1] =
                            el)
                      : (el) =>
                          (generalProtectedVariableAhoraChartRefs.current[1] =
                            el)
                  }
                  option={generateCompleteCurveOption(
                    data.curvas_completas_dp,
                    "Distribución de \n probabilidades por grupo"
                  )}
                  style={{ height: "360px", width: "100%", maxWidth: "440px" }}
                />
              )}
            </Grid>
            <Grid item xs={4} sm={4} md={12} xl={6}>
              <FlexColumn gap="24px">
                <Typography variant="h6" textAlign="center">
                  Métricas Binarias
                </Typography>
                <Grid
                  container
                  spacing={1}
                  columns={{
                    xs: 4,
                    sm: 4,
                    md: 12,
                    xl: 12,
                    alignContent: "flex-start",
                  }}
                >
                  <Grid item xs={2} sm={2} md={3} xl={6}>
                    <FlexColumn
                      alignItems="center"
                      justifycontent="center"
                      sx={{
                        background: "#FFFFFF",
                        border: "3px solid #809F9E",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography align="center" variant="body1">
                        Accuracy <br />{" "}
                        {metrics
                          ? metrics.Accuracy
                          : data.cajitas_metricas_variables.data.Accuracy}
                      </Typography>
                    </FlexColumn>
                  </Grid>
                  <Grid item xs={2} sm={2} md={3} xl={6}>
                    <FlexColumn
                      alignItems="center"
                      justifycontent="center"
                      sx={{
                        background: "#FFFFFF",
                        border: "3px solid #A6819F",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography align="center" variant="body1">
                        Recall <br />{" "}
                        {metrics
                          ? metrics.Recall
                          : data.cajitas_metricas_variables.data.Recall}
                      </Typography>
                    </FlexColumn>
                  </Grid>
                  <Grid item xs={2} sm={2} md={3} xl={6}>
                    <FlexColumn
                      alignItems="center"
                      justifycontent="center"
                      sx={{
                        background: "#FFFFFF",
                        border: "3px solid #FFCDA1",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography align="center" variant="body1">
                        Precisión <br />{" "}
                        {metrics
                          ? metrics.Precision
                          : data.cajitas_metricas_variables.data.Precision}
                      </Typography>
                    </FlexColumn>
                  </Grid>
                  <Grid item xs={2} sm={2} md={3} xl={6}>
                    <FlexColumn
                      alignItems="center"
                      justifycontent="center"
                      sx={{
                        background: "#FFFFFF",
                        border: "3px solid #9FC7D0",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography align="center" variant="body1">
                        F1 <br />{" "}
                        {metrics
                          ? metrics.F1Score
                          : data.cajitas_metricas_variables.data.F1Score}
                      </Typography>
                    </FlexColumn>
                  </Grid>
                </Grid>
                <FlexColumn gap="32px" height="100%" alignItems="center">
                  <Typography align="center" variant="h6">
                    Umbral = {umbral}
                  </Typography>
                  <Box
                    sx={{
                      position: "relative",
                      width: "80%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      sx={{
                        position: "absolute",
                        left: "0px",
                        top: "20px",
                      }}
                    >
                      0
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      sx={{
                        position: "absolute",
                        right: "0px",
                        top: "20px",
                      }}
                    >
                      1
                    </Typography>
                    <Slider
                      value={umbral}
                      min={0}
                      max={1}
                      step={0.01}
                      onChange={(e, newValue) => setUmbral(newValue)}
                      valueLabelDisplay="auto"
                      marks={[]}
                      sx={{
                        "& .MuiSlider-rail": {
                          color: "#02403C",
                          height: "1px",
                        },
                        "& .MuiSlider-track": {
                          color: "#02403C",
                          height: "1px",
                        },
                        "& .MuiSlider-thumb": {
                          backgroundColor: "#02403C",
                        },
                      }}
                    />
                  </Box>
                </FlexColumn>
              </FlexColumn>
            </Grid>
          </Grid>
          <Box
            sx={{
              color: "#4D033F",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{ color: "#4D033F" }}
              variant="h4"
              onClick={() => toogleShowMoredpca()}
            >
              Ver Comparación de rendimiento
            </Typography>
            {showMoredpca ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Box>

          {showMoredpca ? (
            <Grid
              container
              columns={{
                xs: 4,
                sm: 4,
                md: 12,
                xl: 12,
              }}
              sx={{ marginTop: "24px" }}
            >
              <Grid item xs={4} sm={4} md={12} xl={4}>
                <ReactECharts
                  ref={
                    state === "antes"
                      ? (el) =>
                          (generalProtectedVariableAntesChartRefs.current[2] =
                            el)
                      : (el) =>
                          (generalProtectedVariableAhoraChartRefs.current[2] =
                            el)
                  }
                  option={generateCurveOption(
                    data.curva_calibracion,
                    "Calibración"
                  )}
                  style={{ height: "360px", width: "100%", maxWidth: "440px" }}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={12} xl={4}>
                <ReactECharts
                  ref={
                    state === "antes"
                      ? (el) =>
                          (generalProtectedVariableAntesChartRefs.current[4] =
                            el)
                      : (el) =>
                          (generalProtectedVariableAhoraChartRefs.current[4] =
                            el)
                  }
                  option={generateCurveOption(data.curva_roc, "ROC")}
                  style={{ height: "360px", width: "100%", maxWidth: "440px" }}
                />
              </Grid>

              <Grid item xs={4} sm={4} md={12} xl={4}>
                <ReactECharts
                  ref={
                    state === "antes"
                      ? (el) =>
                          (generalProtectedVariableAntesChartRefs.current[3] =
                            el)
                      : (el) =>
                          (generalProtectedVariableAhoraChartRefs.current[3] =
                            el)
                  }
                  option={generateCurveOption(
                    data.curva_precision_recall,
                    "Precision recall"
                  )}
                  style={{ height: "360px", width: "100%", maxWidth: "440px" }}
                />
              </Grid>
            </Grid>
          ) : null}
        </FlexColumn>
      </BorderCard>
    );
  };

  return (
    <PageContainerDashboard>
      {actualData ? (
        !showGraphics ? (
          <Grid
            container
            spacing={2}
            sx={{ p: "20px", alignContent: "flex-start" }}
          >
            <Grid item xs={12}>
              <FlexRow
                justifycontent="space-between"
                alignItems="center"
                gap="48px"
              >
                <FlexColumn>
                  <Typography variant="h5">Mitigación de sesgos</Typography>
                  <Typography variant="body1">
                    En esta sección se muestran los resultados de la mitigación
                    de los sesgos de tu modelo.
                  </Typography>
                </FlexColumn>
                <FlexColumn alignItems="center" gap="8px">
                  <StyledButton
                    sx={{ width: "max-content", minWidth: "100%" }}
                    secondary="true"
                  >
                    <Typography
                      variant="body2"
                      onClick={() =>
                        handleNavigation(
                          `/dashboard/${actualData.id_model}/detection`
                        )
                      }
                    >
                      ← Ver detección de sesgos
                    </Typography>
                  </StyledButton>
                </FlexColumn>
              </FlexRow>
              <StyledDivider sx={{ marginBottom: "28px", marginTop: "28px" }} />
            </Grid>
            <Grid item xs={12}>
              <FlexRow justifycontent="space-between" alignItems="center">
                <Typography variant="h6">{actualData.name}</Typography>
              </FlexRow>
            </Grid>
            <Grid item spacing={2} xs={12}>
              <BorderCard padding="8px">
                {actualData?.biased_variables?.sugerida === "Sin-sesgos" ? (
                  <FlexColumn gap="16px" alignItems="flex-start">
                    <Typography variant="h4">
                      ¡Felicitaciones! No se detectaron sesgos en tu modelo
                    </Typography>
                    <Typography variant="body1">
                      Tu modelo ha pasado la evaluación de sesgos sin encontrar
                      diferencias significativas: ninguna de las variables
                      protegidas presenta métricas con diferencias mayores a 0.1
                      entre grupos. Para mantener la equidad en el tiempo, te
                      recomendamos revisar periódicamente el desempeño de tu
                      modelo entre los distintos grupos poblacionales.
                    </Typography>
                  </FlexColumn>
                ) : actualData?.biased_variables?.lista_vps?.length > 0 ? (
                  <FlexColumn gap="16px" alignItems="flex-start">
                    <Typography variant="h4">Variables</Typography>
                    <FlexColumn>
                      <Typography variant="body1">
                        La variable seleccionada por defecto es la recomendada
                        para mitigar, ya que presenta los mayores sesgos
                        detectados en el proceso. Si prefieres mitigar una
                        variable diferente, elige una de la lista.
                      </Typography>
                    </FlexColumn>
                    <StyledDivider />
                    <FormControl component="fieldset">
                      <FormGroup aria-label="position" column="true">
                        {actualData.biased_variables.lista_vps.map(
                          (variableKey, index) => (
                            <FormControlLabel
                              key={index}
                              value={variableKey}
                              control={
                                <Checkbox
                                  checked={checkedMetrics.includes(variableKey)}
                                  onChange={() => handleCheck(variableKey)}
                                />
                              }
                              label={
                                <FlexColumn>
                                  <Typography
                                    sx={{
                                      textDecoration: checkedMetrics.includes(
                                        variableKey
                                      )
                                        ? "line-through"
                                        : "none",
                                    }}
                                  >
                                    {variableKey}
                                  </Typography>
                                </FlexColumn>
                              }
                              labelPlacement="end"
                              sx={{ margin: "0px", marginBottom: "16px" }}
                            />
                          )
                        )}
                      </FormGroup>
                    </FormControl>
                    <StyledButton
                      variant="contained"
                      onClick={() => mitigate()}
                    >
                      Mitigar sesgos
                    </StyledButton>
                  </FlexColumn>
                ) : (
                  <FlexColumn gap="16px" alignItems="flex-start">
                    <Typography variant="h4">Variables</Typography>
                    <FlexColumn>
                      <Typography variant="body1">
                        La variable seleccionada por defecto es la recomendada
                        para mitigar, ya que presenta los mayores sesgos
                        detectados en el proceso. Si prefieres mitigar una
                        variable diferente, elige una de la lista.
                      </Typography>
                    </FlexColumn>
                    <StyledDivider />
                    <Typography variant="h4">
                      Antes de poder realizar la mitigación de sesgos, debes
                      realizar la detección, regresa al paso anterior.
                    </Typography>
                  </FlexColumn>
                )}
              </BorderCard>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            sx={{ p: "20px", alignContent: "flex-start" }}
          >
            <Grid item xs={12}>
              <FlexRow
                justifycontent="space-between"
                alignItems="center"
                gap="48px"
              >
                <FlexColumn>
                  <Typography variant="h5">Mitigación de sesgos</Typography>
                  <Typography variant="body1">
                    En esta sección se muestran los resultados de la mitigación
                    de los sesgos de tu modelo.
                  </Typography>
                </FlexColumn>
                <FlexColumn alignItems="center" gap="8px">
                  <StyledButton
                    sx={{ width: "max-content", minWidth: "100%" }}
                    secondary="true"
                  >
                    <Typography
                      variant="body2"
                      onClick={() =>
                        handleNavigation(
                          `/dashboard/${actualData.id_model}/detection`
                        )
                      }
                    >
                      ← Ver detección de sesgos
                    </Typography>
                  </StyledButton>
                  <StyledButton
                    sx={{ width: "max-content", minWidth: "100%" }}
                    secondary="true"
                  >
                    <Typography
                      variant="body2"
                      onClick={() =>
                        handleNavigation(
                          `/dashboard/${actualData.id_model}/prediction`
                        )
                      }
                    >
                      Predicción de datos →
                    </Typography>
                  </StyledButton>
                </FlexColumn>
              </FlexRow>
              <StyledDivider sx={{ marginBottom: "28px", marginTop: "28px" }} />
              <FlexRow justifycontent="space-between" alignItems="center">
                <Typography variant="h6">
                  Modelo mitigado para {actualData.name}
                </Typography>
                <FlexRow gap="20px">
                  <StyledButton
                    secondary
                    variant="outlined"
                    disabled={!actualData.mitigated_dataframe || loading}
                    onClick={() =>
                      downloadMitigatedDataframe(actualData.mitigated_dataframe)
                    }
                  >
                    <Typography variant="body2">
                      Descargar las predicciones mitigadas
                    </Typography>
                  </StyledButton>
                  <MitigationReport
                    loading={loading}
                    distributionVariables={distributionVariables}
                    poblationVariable={poblationVariable}
                  />
                </FlexRow>
              </FlexRow>
            </Grid>
            <Grid item xs={12}>
              <BorderCard padding="16px">
                <FlexColumn gap="16px">
                  {actualData.fairness_definition === "eo" ? (
                    <Typography variant="h4">Equalized Odds</Typography>
                  ) : actualData.fairness_definition === "ca" ? (
                    <Typography variant="h4">
                      Mitigación por calibración
                    </Typography>
                  ) : (
                    <Typography
                      variant="h4"
                      sx={{ textTransform: "uppercase" }}
                    >
                      {actualData.fairness_definition}
                    </Typography>
                  )}

                  <Typography variant="body1">
                    {dpCaText && dpCaText}
                  </Typography>
                  {loading ? (
                    <StyledSkeletonRectangular />
                  ) : (
                    <Grid
                      container
                      spacing={2}
                      columns={{ xs: 4, sm: 4, md: 12, xl: 12 }}
                    >
                      {generalProtectedVariablesAntes !== null ? (
                        <Grid item xs={4} sm={4} md={6} xl={6}>
                          <MetricsBox
                            data={generalProtectedVariablesAntes}
                            state="antes"
                          />
                        </Grid>
                      ) : (
                        <>
                          {generalProtectedVariablesFpr !== null && (
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={5}
                              xl={4}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ReactECharts
                                ref={generalProtectedVariableFprChartRefs}
                                option={generateGeneralProtectedOption(
                                  generalProtectedVariablesFpr,
                                  "cambio de FPR por grupo"
                                )}
                                style={{
                                  height: "360px",
                                  width: "100%",
                                  maxWidth: "440px",
                                }}
                              />
                            </Grid>
                          )}
                          {generalProtectedVariablesTpr !== null && (
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={5}
                              xl={4}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ReactECharts
                                ref={generalProtectedVariableTprChartRefs}
                                option={generateGeneralProtectedOption(
                                  generalProtectedVariablesTpr,
                                  "cambio de TPR por grupo"
                                )}
                                style={{
                                  height: "360px",
                                  width: "100%",
                                  maxWidth: "440px",
                                }}
                              />
                            </Grid>
                          )}
                          {generalProtectedVariablesEo !== null && (
                            <Grid
                              container
                              columns={{ xs: 4, sm: 4, md: 12, xl: 12 }}
                            >
                              {Object.keys(generalProtectedVariablesEo).map(
                                (key, index) => (
                                  <Grid
                                    item
                                    xs={4}
                                    sm={4}
                                    md={6}
                                    xl={6}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <ReactECharts
                                      ref={(el) =>
                                        (generalProtectedVariableEoChartRefs.current[
                                          index
                                        ] = el)
                                      }
                                      option={generateGeneralProtectedOption(
                                        generalProtectedVariablesEo[key],
                                        key
                                      )}
                                      style={{
                                        height: "360px",
                                        width: "100%",
                                        maxWidth: "440px",
                                      }}
                                    />
                                  </Grid>
                                )
                              )}
                            </Grid>
                          )}
                        </>
                      )}
                      {generalProtectedVariablesAhora !== null ? (
                        <Grid item xs={4} sm={4} md={6} xl={6}>
                          <MetricsBox
                            data={generalProtectedVariablesAhora}
                            state="ahora"
                          />
                        </Grid>
                      ) : actualData.fairness_definition === "eo" ? (
                        <Grid item xs={4} sm={4} md={12} xl={12}>
                          <Grid
                            container
                            spacing={2}
                            columns={{ xs: 4, sm: 4, md: 12, xl: 12 }}
                          >
                            {generalMetricsVariables !== null &&
                              Object.keys(generalMetricsVariables).map(
                                (key, index) => (
                                  <Grid item xs={4} sm={4} md={6} xl={3}>
                                    <ReactECharts
                                      ref={(el) =>
                                        (generalMetricsVariableChartRefs.current[
                                          index
                                        ] = el)
                                      }
                                      option={generateGeneralMetricsOption(
                                        generalMetricsVariables[key],
                                        key
                                      )}
                                      style={{
                                        height: "360px",
                                        width: "100%",
                                        maxWidth: "440px",
                                      }}
                                    />
                                  </Grid>
                                )
                              )}
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={4} sm={4} md={7} xl={8}>
                          <Grid
                            container
                            spacing={2}
                            columns={{ xs: 4, sm: 4, md: 12, xl: 12 }}
                          >
                            {generalMetricsVariables !== null &&
                              Object.keys(generalMetricsVariables).map(
                                (key, index) => (
                                  <Grid item xs={4} sm={4} md={6} xl={3}>
                                    <ReactECharts
                                      ref={(el) =>
                                        (generalMetricsVariableChartRefs.current[
                                          index
                                        ] = el)
                                      }
                                      option={generateGeneralMetricsOption(
                                        generalMetricsVariables[key],
                                        key
                                      )}
                                      style={{
                                        height: "360px",
                                        width: "100%",
                                        maxWidth: "440px",
                                      }}
                                    />
                                  </Grid>
                                )
                              )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </FlexColumn>
              </BorderCard>
            </Grid>
            <Grid item xs={12}>
              <BorderCard padding="16px">
                <FlexColumn gap="16px">
                  <Typography variant="h5" textAlign="center">
                    Análisis por Grupo Poblacional
                  </Typography>
                  {loading ? (
                    <StyledSkeletonRectangular />
                  ) : (
                    distributionVariables !== null &&
                    Object.keys(distributionVariables).map((key, index) => (
                      <Grid
                        container
                        spacing={2}
                        columns={{ xs: 4, sm: 4, md: 12, xl: 12 }}
                        sx={{ alignItems: "center" }}
                      >
                        <Grid
                          item
                          xs={4}
                          sm={4}
                          md={4}
                          xl={4}
                          key={`bar-${index}`}
                        >
                          <Typography>
                            <ReactECharts
                              ref={(el) =>
                                (distributionVariableChartRefs.current[index] =
                                  el)
                              }
                              option={generateDistributionOption(
                                distributionVariables[key],
                                key
                              )}
                              style={{
                                height: "360px",
                                width: "100%",
                                maxWidth: "440px",
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sm={4}
                          md={8}
                          xl={8}
                          key={`text-${index}`}
                        >
                          <Typography
                            sx={{
                              paddingTop: "48px",
                              paddingBottom: "12px",
                              borderBottom: "1px solid #D9D9D9",
                            }}
                          >
                            {distributionVariables[key].text.general}
                          </Typography>
                          {showMore[key] ? (
                            <Typography sx={{ paddingTop: "12px" }}>
                              {distributionVariables[key].text.ver_mas}
                            </Typography>
                          ) : null}

                          <StyledButton
                            variant="contained"
                            onClick={() => handleToggleShowMore(key)}
                            sx={{ marginTop: "12px" }}
                          >
                            {showMore[key] ? "Ver menos" : "Ver más"}
                          </StyledButton>
                        </Grid>
                      </Grid>
                    ))
                  )}
                  {loading ? (
                    <StyledSkeletonRectangular />
                  ) : (
                    poblationVariable !== null &&
                    (generalProtectedVariablesAntes.curvas_completas_ca
                      ? Object.keys(poblationVariable.Histograma).map(
                          (key, index) => (
                            <Grid
                              container
                              spacing={2}
                              columns={{ xs: 4, sm: 4, md: 12, xl: 12 }}
                              key={key}
                              sx={{ alignItems: "center" }}
                            >
                              <Grid item xs={4} sm={4} md={6} xl={3}>
                                {/* Histograma */}
                                <ReactECharts
                                  ref={(el) =>
                                    (poblationVariableChartRefs.current[
                                      index * 2
                                    ] = el)
                                  }
                                  option={generateCaHistogramaOption(
                                    poblationVariable.Histograma[key],
                                    key
                                  )}
                                  style={{
                                    height: "360px",
                                    width: "100%",
                                    maxWidth: "440px",
                                  }}
                                />
                              </Grid>
                              <Grid item xs={4} sm={4} md={6} xl={3}>
                                {/* Línea */}
                                <ReactECharts
                                  ref={(el) =>
                                    (poblationVariableChartRefs.current[
                                      index * 2 + 1
                                    ] = el)
                                  }
                                  option={generateCaLineasOption(
                                    poblationVariable.Lineas[key],
                                    key
                                  )}
                                  style={{
                                    height: "360px",
                                    width: "100%",
                                    maxWidth: "440px",
                                  }}
                                />
                              </Grid>
                              <Grid item xs={4} sm={4} md={12} xl={6}>
                                <Typography
                                  sx={{
                                    paddingTop: "48px",
                                    paddingBottom: "12px",
                                    borderBottom: "1px solid #D9D9D9",
                                  }}
                                >
                                  {poblationVariable.Lineas[key].texto}
                                </Typography>
                              </Grid>
                            </Grid>
                          )
                        )
                      : Object.keys(poblationVariable).map((key, index) => (
                          <Grid
                            container
                            spacing={2}
                            columns={{ xs: 4, sm: 4, md: 12, xl: 12 }}
                            key={key}
                            sx={{ alignItems: "center" }}
                          >
                            <Grid item xs={4} sm={4} md={4} xl={4}>
                              <ReactECharts
                                ref={(el) =>
                                  (poblationVariableChartRefs.current[index] =
                                    el)
                                }
                                option={generateDpOption(
                                  poblationVariable[key],
                                  key
                                )}
                                style={{
                                  height: "360px",
                                  width: "100%",
                                  maxWidth: "440px",
                                }}
                              />
                            </Grid>
                            <Grid item xs={4} sm={4} md={8} xl={8}>
                              <Typography
                                sx={{
                                  paddingTop: "48px",
                                  paddingBottom: "12px",
                                  borderBottom: "1px solid #D9D9D9",
                                }}
                              >
                                {poblationVariable[key].texto}
                              </Typography>
                            </Grid>
                          </Grid>
                        )))
                  )}
                </FlexColumn>
              </BorderCard>
            </Grid>
          </Grid>
        )
      ) : (
        <Grid
          container
          spacing={3}
          sx={{ p: "20px", alignContent: "flex-start" }}
        />
      )}
    </PageContainerDashboard>
  );
};

export default Mitigation;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Button, Toolbar } from "@mui/material";
import { styled } from "@mui/system";
import logoCriteria from "../../../images/logoCriteria.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../../../context/AuthProvider";
import LogoutModal from "../../shared/modal/LogoutModal";

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  position: "fixed",
  background: theme.palette.background.default,
  color: theme.palette.primary.main,
  zIndex: theme.zIndex.drawer + 1,
  borderBottom: "1px solid" + theme.palette.primary.main,
}));

const StyledAppBarDashboard = (props) => {
  const navigate = useNavigate();
  const { handleLogout } = useAuth();

  const logout = () => {
    handleNavigation("/");
    handleLogout();
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <CustomAppBar {...props}>
      <LogoutModal open={open} onClose={handleClose} handleLogout={logout} />
      <Toolbar>
        <img
          src={logoCriteria}
          alt="logo"
          onClick={() => handleNavigation("/")}
        />
        {props.children}
        <Button sx={{ marginLeft: "auto" }} onClick={() => setOpen(true)}>
          <LogoutIcon fontSize="large" />
        </Button>
      </Toolbar>
    </CustomAppBar>
  );
};

export default StyledAppBarDashboard;

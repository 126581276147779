import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({
  height: "12px",
  width: "12px",
}));

const IconPayment = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="61"
        viewBox="0 0 10 61"
        fill="none"
      >
        <circle
          cx="5"
          cy="6.5"
          r="5"
          transform="rotate(90 5 6.5)"
          fill="#02403C"
        />
        <rect
          x="6"
          y="11.5"
          width="38"
          height="2"
          transform="rotate(90 6 11.5)"
          fill="#02403C"
        />
        <circle
          cx="5"
          cy="54.5"
          r="4"
          transform="rotate(90 5 54.5)"
          stroke="#02403C"
          stroke-width="2"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconPayment;

import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled, Typography } from "@mui/material";
import IconGlossaryItem from "../../icons/IconGlossaryItem";
import BorderCard from "../../shared/container/BorderCard";
import FlexColumn from "../../shared/container/FlexColumn";

const CustomAccordion = styled(Accordion)(({ theme, ...props }) => ({
  boxShadow: "none",
  "& img": {
    maxWidth: "60%",
  },
}));

const StyledAccordion = (props) => {
  const { title, details, image, details2, image2, ...otherProps } = props;

  // Helper function to render split text
  const renderSplitText = (text) =>
    text.split("/n").map((line, index) => (
      <Typography key={index} variant="body1">
        {line}
      </Typography>
    ));

  return (
    <BorderCard padding="8px">
      <CustomAccordion {...otherProps}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: "#02403C" }} fontSize="large" />
          }
          aria-controls="panel-content"
          id="panel-header"
        >
          <IconGlossaryItem
            sx={{ width: "40px", height: "40px", marginRight: "16px" }}
          />
          <Typography variant="h4">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FlexColumn gap="12px" alignItems="flex-start">
            {details && renderSplitText(details)}
            {image && <img src={image} alt={title} />}
            {details2 && renderSplitText(details2)}
            {image2 && <img src={image2} alt={title} />}
          </FlexColumn>
        </AccordionDetails>
      </CustomAccordion>
    </BorderCard>
  );
};

export default StyledAccordion;

import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import {
  PageContainerDashboard,
  BorderCard,
  FlexColumn,
  FlexRow,
} from "../../components/shared/container/Index";
import { Grid, TableContainer, Typography } from "@mui/material";
import StyledDivider from "../../components/common/divider/StyledDivider";
import ReactECharts from "echarts-for-react";
import StyledTable from "../../components/common/table/StyledTable";
import StyledButton from "../../components/common/button/StyledButton";

const DataView = () => {
  const navigate = useNavigate();
  const { actualData } = useContext(DataContext);
  const [pie, setPie] = useState(null);
  const [bar, setBar] = useState(null);
  const [variables, setVariables] = useState([]);
  const [rowsTable, setRowsTable] = useState([]);

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (actualData && actualData.data_view) {
      setPie(actualData.data_view.etiqueta_real);
      setBar(actualData.data_view.prediccion);
      setVariables(
        Object.keys(actualData.data_view)
          .filter((key) => key !== "prediccion" && key !== "etiqueta_real")
          .map((key) => ({ [key]: actualData.data_view[key] }))
      );
      const transformedData = Object.keys(actualData.original_dataframe).map(
        (key) => {
          return {
            header: key,
            values: actualData.original_dataframe[key],
          };
        }
      );
      setRowsTable(transformedData);
    }
  }, [actualData]);

  const pieOption = pie
    ? {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            const total = pie.data.reduce((sum, item) => sum + item.value, 0);
            const percentage = ((params.value / total) * 100).toFixed(2);
            return `<b>${params.name}:</b> ${percentage}%`;
          },
        },
        legend: {
          orient: "vertical",
          left: "right",
        },
        series: [
          {
            name: "Etiqueta real",
            type: pie.tipo,
            radius: "80%",
            data: pie.data.map((item, index) => ({
              ...item,
              itemStyle: {
                color: ["#9FC7D0", "#D9E9EC"][index % 2],
              },
            })),
            label: {
              show: true,
              position: "inside", // Posiciona las etiquetas dentro de las secciones del pastel
              formatter: (params) => {
                const total = pie.data.reduce(
                  (sum, item) => sum + item.value,
                  0
                );
                const percentage = ((params.value / total) * 100).toFixed(2);
                return `${percentage}%`; // Muestra solo el porcentaje
              },
              fontSize: 12,
              color: "#000", // Cambia el color si es necesario para mejor visibilidad
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      }
    : {};

  const barOption = bar
    ? {
        tooltip: {},
        xAxis: [
          {
            name: "Probabilidad Predicha",
            nameLocation: "center",
            nameGap: 0,
            nameTextStyle: {
              align: "center",
              verticalAlign: "top",
              padding: [30, 0, 0, 0],
            },
            type: "value",
          },
        ],
        grid: {
          top: 20,
          left: 50,
        },
        yAxis: [
          {
            name: "Frecuencia",
            nameLocation: "center",
            nameGap: 30,
            nameTextStyle: {
              align: "center",
              padding: [0, 0, 0, 0],
            },
            type: "value",
          },
        ],
        series: [
          {
            name: "Predicción",
            type: "bar",
            barWidth: "100%",
            data: bar.data,
            itemStyle: {
              color: "#A6819F",
            },
          },
          {
            name: "Línea suavizada",
            type: "line",
            smooth: true,
            data: bar.data,
            itemStyle: {
              color: "#9FC7D0",
            },
          },
        ],
      }
    : {};

  const generateBarOption = (variableName, variableData) => {
    // Calculamos el total de los datos
    const total = (
      variableData.data.series_data || variableData.data.map((item) => item[1])
    ).reduce((sum, value) => sum + value, 0);

    return {
      title: {
        text: `${variableName}`,
        left: "center",
      },
      tooltip: {
        trigger: "item", // Muestra el tooltip para cada ítem
        formatter: function (params) {
          const value = params.value; // Valor del dato
          const percentage = ((value / total) * 100).toFixed(2); // Calculamos el porcentaje
          return `<b>${params.name}:</b> ${percentage}%`; // Formato del tooltip
        },
      },
      grid: {
        left: 50,
      },
      yAxis: [
        {
          name: "Valores",
          nameTextStyle: {
            align: "left",
            padding: [0, 0, 0, 0],
          },
          type: "category",
          data:
            variableData.data.x_axis_data ||
            variableData.data.map((item) => item[0]),
          axisLabel: {
            formatter: function (value) {
              return value.length > 6
                ? value.match(/.{1,6}/g).join("\n")
                : value;
            },
            interval: 0,
          },
        },
      ],
      xAxis: [
        {
          name: "Frecuencia",
          nameLocation: "center",
          nameGap: 30,
          nameTextStyle: {
            align: "center",
            padding: [0, 0, 0, 0],
          },
          type: "value",
        },
      ],
      series: [
        {
          name: variableData.tipo === "histograma" ? "Histograma" : "Barras",
          type: "bar",
          barWidth: "95%",
          data:
            variableData.data.series_data ||
            variableData.data.map((item) => item[1]),
          itemStyle: {
            color: "#A6819F",
          },
        },
      ],
    };
  };

  return (
    <PageContainerDashboard>
      {actualData ? (
        <Grid
          container
          spacing={2}
          sx={{ p: "20px", alignContent: "flex-start" }}
        >
          <Grid item xs={12}>
            <FlexRow justifycontent="space-between" alignItems="center">
              <FlexColumn>
                <Typography variant="h5">Visualización de modelo</Typography>
                <Typography variant="body1">
                  Aquí podrás visualizar tus datos
                </Typography>
              </FlexColumn>
              <FlexColumn alignItems="center" gap="8px">
                <StyledButton
                  sx={{ width: "max-content", minWidth: "100%" }}
                  secondary="true"
                >
                  <Typography
                    variant="body2"
                    onClick={() =>
                      handleNavigation(
                        `/dashboard/${actualData.id_model}/performance`
                      )
                    }
                  >
                    Ver rendimiento técnico →
                  </Typography>
                </StyledButton>
              </FlexColumn>
            </FlexRow>

            <StyledDivider sx={{ marginBottom: "28px", marginTop: "28px" }} />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              columns={{ xs: 4, sm: 12, xl: 12, alignContent: "flex-start" }}
            >
              <Grid item zeroMinWidth xs={4} sm={6} xl={6}>
                <BorderCard padding="16px">
                  <FlexColumn gap="8px">
                    <Typography variant="h4">
                      Previsualización de datos
                    </Typography>
                    <TableContainer sx={{ maxHeight: "260px", padding: "8px" }}>
                      <StyledTable borderaxis="both">
                        <thead>
                          <tr key="Preview">
                            {rowsTable.map((row) => (
                              <th key={row.header}>{row.header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {rowsTable[0]?.values
                            .slice(0, 5)
                            .map((_, rowIndex) => (
                              <tr key={rowIndex}>
                                {rowsTable.map((row) => (
                                  <td key={row.header + rowIndex}>
                                    {typeof row.values[rowIndex] === "number"
                                      ? Number.isInteger(row.values[rowIndex])
                                        ? row.values[rowIndex]
                                        : row.values[rowIndex].toFixed(2)
                                      : row.values[rowIndex]}
                                  </td>
                                ))}
                              </tr>
                            ))}
                        </tbody>
                      </StyledTable>
                    </TableContainer>
                  </FlexColumn>
                </BorderCard>
              </Grid>
              <Grid item zeroMinWidth xs={4} sm={6} xl={6}>
                <BorderCard padding="16px">
                  <FlexColumn gap="8px">
                    <FlexRow gap="8px" width="100%">
                      <Typography
                        variant="h4"
                        sx={{ width: "50%" }}
                        textAlign="center"
                      >
                        Etiqueta real
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{ width: "50%" }}
                        textAlign="center"
                      >
                        Histograma de la Predicción
                      </Typography>
                    </FlexRow>

                    <Grid
                      container
                      spacing={1}
                      columns={{ xs: 4, sm: 12, xl: 12 }}
                    >
                      <Grid item zeroMinWidth xs={4} sm={6} xl={6}>
                        <ReactECharts
                          option={pieOption}
                          style={{
                            height: "260px",
                            width: "100%",
                            maxWidth: "440px",
                          }}
                        />
                      </Grid>
                      <Grid item zeroMinWidth xs={4} sm={6} xl={6}>
                        <ReactECharts
                          option={barOption}
                          style={{
                            height: "260px",
                            width: "100%",
                            maxWidth: "440px",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </FlexColumn>
                </BorderCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BorderCard padding="16px">
              <FlexColumn gap="16px">
                <Typography variant="h4">
                  Distribución de las variables protegidas
                </Typography>
                <Grid
                  container
                  spacing={2}
                  columns={{
                    xs: 4,
                    sm: 12,
                    xl: 12,
                    alignContent: "flex-start",
                  }}
                >
                  {variables.map((variable, index) => {
                    const variableName = Object.keys(variable)[0];
                    const variableData = Object.values(variable)[0];
                    return (
                      <Grid item zeroMinWidth xs={4} sm={3} xl={3} key={index}>
                        <ReactECharts
                          option={generateBarOption(variableName, variableData)}
                          style={{
                            height: "360px",
                            width: "100%",
                            maxWidth: "440px",
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </FlexColumn>
            </BorderCard>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={3}
          sx={{ p: "20px", alignContent: "flex-start" }}
        />
      )}
    </PageContainerDashboard>
  );
};

export default DataView;

import PageContainer from "../../components/shared/container/PageContainer";
import MaxWidthContainer from "../../components/shared/container/MaxWidthContainer";
import StyledFooter from "../../components/common/navigation/StyledFooter";
import FlexColumn from "../../components/shared/container/FlexColumn";
import FlexRow from "../../components/shared/container/FlexRow";
import { Box, Typography } from "@mui/material";
import StyledButton from "../../components/common/button/StyledButton";
import { Hero } from "../../images/Index";

const TermsAndConditions = () => {
  return (
    <PageContainer>
      <Box
        sx={{
          backgroundImage: `url(${Hero})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "360px !important",
          marginBottom: "80px",
        }}
      />
      <MaxWidthContainer>
        <FlexColumn width="100%">
          <Typography
            variant="h1"
            sx={{
              marginBottom: "16px",
            }}
          >
            Términos y Condiciones de Uso de criteria
          </Typography>
          <FlexRow
            gap="10px"
            alignItems="center"
            sx={{
              marginBottom: "40px",
            }}
          >
            <StyledButton variant="outlined" secondary="true">
              <Typography
                variant="body1"
                sx={{
                  textWrap: "nowrap",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Criteria
              </Typography>
            </StyledButton>
            <Typography variant="h6">
              Auditoría Algorítmica, Noviembre 2024
            </Typography>
          </FlexRow>

          <Typography
            variant="body1"
            sx={{
              marginBottom: "16px",
              borderLeft: "5px solid #02403C",
              paddingLeft: "16px",
            }}
          >
            Al registrarte en la plataforma de criteria, confirmas que has
            leído, entendido y aceptado estar vinculado por los presentes
            Términos y Condiciones de Uso (en adelante, los "Términos").
          </Typography>

          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Estos Términos rigen el uso de criteria, una herramienta diseñada
            para detectar y mitigar sesgos en modelos supervisados. Nos
            reservamos el derecho de modificar, añadir o eliminar disposiciones
            de estos Términos en cualquier momento y a nuestra discreción. Si
            realizamos cambios materiales, te notificaremos mediante los medios
            de contacto que hayas proporcionado. El uso continuo de criteria
            después de dichos cambios implica tu aceptación de los Términos
            actualizados.
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            1. Consentimiento del Usuario
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Al utilizar criteria, declaras y garantizas que:
            <ul style={{ margin: "0px" }}>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Tienes al menos 18 años de edad o la mayoría de edad legal en
                  tu jurisdicción.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Posees la autoridad legal y las autorizaciones necesarias para
                  aceptar estos Términos y, si corresponde, para vincular a la
                  institución que representas.
                </Typography>
              </li>
            </ul>
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            2. Uso de Datos
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Criteria se alimenta de los datos suministrados por los usuarios,
            por lo tanto la información que se suministre no debe infringir los
            derechos de los titulares de datos personales en los que tenga poder
            el cliente. Recuerde que cualquier incidente relacionado con datos
            personales se deberá reportar ante la SIC.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Para conocer los lineamientos sobre la protección de datos en
            criteria, te invitamos a leer la política de privacidad de datos
            personales de Quantil (link), la empresa que desarrolló y gestiona
            esta herramienta.
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            3. Restricciones de Uso
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            No puedes utilizar criteria para:
            <ul style={{ margin: "0px" }}>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Propósitos ilegales, fraudulentos o que violen cualquier ley o
                  regulación aplicable.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Introducir contenido malicioso, como software dañino, virus o
                  cualquier elemento que pueda comprometer la seguridad de
                  criteria o sus usuarios.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Realizar actividades que puedan derivar en discriminación,
                  exclusión o daños a individuos o grupos.
                </Typography>
              </li>
            </ul>
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            4. Políticas de Contenido y Conducta
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Tenga en cuenta que los precios de los servicios se pueden reajustar
            unilateralmente por el proveedor.
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            5. Propiedad Intelectual
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Todos los derechos relacionados con el software, diseño, gráficos,
            logotipos, videos, algoritmos y funcionalidades de criteria son
            propiedad exclusiva de sus desarrolladores. No se otorga ninguna
            licencia para modificar, distribuir, reproducir o crear obras
            derivadas de criteria sin autorización expresa por escrito.
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            6. Precios
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Los precios que cobramos por usar nuestros servicios se enumeran en
            el sitio web. Tenga en cuenta que los precios de los servicios se
            pueden reajustar unilateralmente por el proveedor.
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            7. Limitación de Responsabilidad
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            En la máxima medida permitida por la ley, los desarrolladores de
            criteria no serán responsables por:
            <ul style={{ margin: "0px" }}>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Daños directos, indirectos, incidentales, punitivos o
                  consecuentes derivados del uso o la imposibilidad de usar
                  criteria.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Pérdidas de datos, ingresos, oportunidades de negocio o
                  cualquier daño intangible relacionado con el uso de criteria y
                  de los modelos mitigados que se crean en la herramienta.
                </Typography>
              </li>
            </ul>
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            8. Modificaciones a los Términos
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Los términos y condiciones del servicio pueden cambiar sin previo
            aviso al cliente.
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            9. Finalización del Servicio
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Nos reservamos el derecho de suspender o terminar tu acceso a
            criteria, sin previo aviso, si consideramos que has violado estos
            Términos o cualquier regulación aplicable.
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            10. Comunicaciones
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Al utilizar criteria, aceptas recibir comunicaciones relacionadas
            con el servicio, incluidas notificaciones sobre actualizaciones,
            mantenimiento o cambios en estos Términos. Puedes solicitar no
            recibir comunicaciones promocionales en cualquier momento.
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            11. Ley Aplicable
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Si el proveedor del servicio se encuentra en Colombia, cualquier
            requerimiento o resolución de controversias será más fácil de
            reclamar a comparación si el proveedor se encuentra en otro país.
            Tenga en cuenta que este servicio se regirá por lo dispuesto en el
            Estatuto del Consumidor Ley 1480 de 2011.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Si tienes preguntas o comentarios sobre estos Términos, por favor
            contáctanos a través de melissa.robles@quantil.com.co.
          </Typography>
          <Typography
            variant="h6"
            sx={{ marginTop: "16px", marginBottom: "8px", textAlign: "right" }}
          >
            Fecha de última actualización: 25 Nov 2024
          </Typography>
        </FlexColumn>
      </MaxWidthContainer>
      <StyledFooter />
    </PageContainer>
  );
};

export default TermsAndConditions;

import React, { createContext, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useApiClient } from "../api/apiService";
import { toast } from "react-toastify";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { put, get } = useApiClient();
  const [actualData, setActualData] = useState(null);
  const [mitigationVars, setMitigationVars] = useState([]);
  const [dataView, setDataview] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    const savedModel = sessionStorage.getItem("selectedModel");
    if (savedModel) {
      loadModelById(JSON.parse(savedModel));
    } else if (id) {
      loadModelById(id);
    }
    console.log(location.pathname);
  }, []);

  const loadModelById = async (modelId) => {
    setTimeout(async function () {
      try {
        const response = await get("get_model/" + modelId);
        selectData(response.data);
      } catch (error) {
        if (error.response && error.response.data) {
          toast.error(`Error: ${error.response.data} 1`);
        } else {
          toast.error("Error: Ocurrió un error al cargar el modelo.");
        }
      }
    }, 1000);
  };

  const updateModel = async (newModel) => {
    try {
      const response = await put(
        "update_model/" + actualData.id_model,
        newModel
      );
    } catch (error) {
      if (error.response && error.response.msg) {
        toast.error(`Error: ${error.response.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al actualizar el modelo.");
      }
    }
  };

  const addPerformanceData = (optimal_threshold) => {
    const actualUmbral = actualData.optimal_threshold;
    const newModel = {
      ...actualData,
      optimal_threshold: optimal_threshold,
      phase:
        actualData.phase === "Visualización de datos"
          ? "Rendimiento técnico"
          : actualData.phase,
    };
    setActualData((prevActualData) => ({
      ...prevActualData,
      optimal_threshold: optimal_threshold,
      phase:
        actualData.phase === "Visualización de datos"
          ? "Rendimiento técnico"
          : actualData.phase,
    }));
    if (actualUmbral !== null) {
      toast.success("Se ha actualizado el umbral con éxito");
    }
    updateModel(newModel);
  };

  const addDetectionData = (
    intersect_dataframe,
    metrics_dataframe,
    fairness_definition,
    detection_tuples,
    biased_variables
  ) => {
    const newModel = {
      ...actualData,
      intersect_dataframe: intersect_dataframe,
      metrics_dataframe: metrics_dataframe,
      fairness_definition: fairness_definition,
      detection_tuples: detection_tuples,
      biased_variables: biased_variables,
      phase:
        actualData.phase === "Rendimiento técnico"
          ? "Detección de sesgos"
          : actualData.phase,
    };
    setActualData((prevActualData) => ({
      ...prevActualData,
      intersect_dataframe: intersect_dataframe,
      metrics_dataframe: metrics_dataframe,
      fairness_definition: fairness_definition,
      detection_tuples: detection_tuples,
      biased_variables: biased_variables,
      phase:
        actualData.phase === "Rendimiento técnico"
          ? "Detección de sesgos"
          : actualData.phase,
    }));
    updateModel(newModel);
  };

  const addMitigationData = (
    mitigated_dataframe,
    mitigated_metrics_dataframe,
    mitigate_variable
  ) => {
    const newModel = {
      ...actualData,
      mitigated_dataframe: mitigated_dataframe,
      mitigated_metrics_dataframe: mitigated_metrics_dataframe,
      mitigate_variable: mitigate_variable,
      phase:
        actualData.phase === "Detección de sesgos"
          ? "Mitigación de sesgos"
          : actualData.phase,
    };
    setActualData((prevActualData) => ({
      ...prevActualData,
      mitigated_dataframe: mitigated_dataframe,
      mitigated_metrics_dataframe: mitigated_metrics_dataframe,
      mitigate_variable: mitigate_variable,
      phase:
        actualData.phase === "Detección de sesgos"
          ? "Mitigación de sesgos"
          : actualData.phase,
    }));
    updateModel(newModel);
  };

  const addPredictionData = () => {
    const newModel = {
      ...actualData,
      prediction: true,
      phase:
        actualData.phase === "Mitigación de sesgos"
          ? "Predicción de nuevos datos"
          : actualData.phase,
    };
    setActualData((prevActualData) => ({
      ...prevActualData,
      prediction: true,
      phase:
        actualData.phase === "Mitigación de sesgos"
          ? "Predicción de nuevos datos"
          : actualData.phase,
    }));
    updateModel(newModel);
  };

  const deleteData = () => {
    setActualData(null);
    sessionStorage.removeItem("selectedModel");
    navigate("/dashboard");
  };

  const selectData = async (data) => {
    await setActualData(data);
    console.log("Consulta modelo");
    sessionStorage.setItem("selectedModel", JSON.stringify(data.id_model));
    setMitigationVars([]);
    const phase = data.phase;
    console.log(data.phase);
    if (
      location.pathname === "/dashboard" ||
      location.pathname === "/dashboard/load-data"
    ) {
      if (phase === "Visualización de datos") {
        navigate(`/dashboard/${data.id_model}/data-view`);
      } else if (phase === "Rendimiento técnico") {
        navigate(`/dashboard/${data.id_model}/performance`);
      } else if (phase === "Detección de sesgos") {
        navigate(`/dashboard/${data.id_model}/detection`);
      } else if (phase === "Mitigación de sesgos") {
        navigate(`/dashboard/${data.id_model}/mitigation`);
      } else if (phase === "Predicción de nuevos datos") {
        navigate(`/dashboard/${data.id_model}/prediction`);
      }
    }
  };

  const toogleDataView = () => {
    setDataview(!dataView);
  };

  return (
    <DataContext.Provider
      value={{
        addPerformanceData,
        addDetectionData,
        addMitigationData,
        addPredictionData,
        deleteData,
        actualData,
        selectData,
        mitigationVars,
        setMitigationVars,
        dataView,
        toogleDataView,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const CustomBox = styled(Box)(({ theme, yellow, purple, ...props }) => ({
  height: "30px",
  minWidth: "30px",
  width: "30px",
  borderRadius: "100%",
  padding: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: yellow ? "#FFF0D3" : purple ? "#FFF5FC" : "#02403C",
  "& .MuiSvgIcon-root": {
    width: "16px",
    color: yellow ? "#FF9B43" : purple ? "#4D033F" : "#FFFFFF",
  },
}));

const IconStepperBox = (props) => {
  return <CustomBox {...props}>{props.children}</CustomBox>;
};

export default IconStepperBox;

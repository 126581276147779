import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({}));

const IconCheck = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="118"
        height="117"
        viewBox="0 0 118 117"
        fill="none"
      >
        <path
          d="M50.5846 85.3649L91.7096 44.2399L83.543 36.0732L50.5846 69.0316L33.9596 52.4066L25.793 60.5732L50.5846 85.3649ZM58.7513 116.865C50.6819 116.865 43.0985 115.334 36.0013 112.271C28.9041 109.209 22.7305 105.052 17.4805 99.8024C12.2305 94.5524 8.07422 88.3788 5.01172 81.2816C1.94922 74.1844 0.417969 66.601 0.417969 58.5316C0.417969 50.4621 1.94922 42.8788 5.01172 35.7816C8.07422 28.6844 12.2305 22.5107 17.4805 17.2607C22.7305 12.0107 28.9041 7.85449 36.0013 4.79199C43.0985 1.72949 50.6819 0.198242 58.7513 0.198242C66.8207 0.198242 74.4041 1.72949 81.5013 4.79199C88.5985 7.85449 94.7721 12.0107 100.022 17.2607C105.272 22.5107 109.428 28.6844 112.491 35.7816C115.553 42.8788 117.085 50.4621 117.085 58.5316C117.085 66.601 115.553 74.1844 112.491 81.2816C109.428 88.3788 105.272 94.5524 100.022 99.8024C94.7721 105.052 88.5985 109.209 81.5013 112.271C74.4041 115.334 66.8207 116.865 58.7513 116.865Z"
          fill="#02403C"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconCheck;

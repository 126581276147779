import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const CustomContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1560px",
  margin: "0 auto",
  padding: "0px 80px",
}));

const MaxWidthContainer = (props) => {
  return <CustomContainer {...props}>{props.children}</CustomContainer>;
};

export default MaxWidthContainer;

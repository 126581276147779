import React, { useEffect, useState, useContext } from "react";
import { DataContext } from "../../context/DataContext";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TableContainer,
  Typography,
} from "@mui/material";
import StyledButton from "../../components/common/button/StyledButton";
import StyledDivider from "../../components/common/divider/StyledDivider";
import {
  PageContainerDashboard,
  ShadowCard,
  BorderCard,
  FlexColumn,
  FlexRow,
} from "../../components/shared/container/Index";
import StyledTable from "../../components/common/table/StyledTable";
import StyledStepper from "../../components/common/stepper/StyledStepper";
import StyledInputBox from "../../components/common/input/StyledInputBox";
import StyledInputButton from "../../components/common/input/StyledInputButton";
import IconInfo from "../../components/icons/IconInfo";
import { useApiClient } from "../../api/apiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadNameModal from "../../components/shared/modal/LoadNameModal";
import { useAuth } from "../../context/AuthProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function createData(y1, y2, vp1, vp2, etc, vpn) {
  return { y1, y2, vp1, vp2, etc, vpn };
}

const rows = [
  createData("0", "1", "A", "A", "...", "10"),
  createData("0", "1", "A", "A", "...", "10"),
  createData("0", "1", "A", "A", "...", "10"),
  createData("0", "1", "A", "A", "...", "10"),
];

const LoadData = () => {
  const { post, get } = useApiClient();
  const [loading, setLoading] = useState(false);
  const { userId } = useAuth();
  const { selectData } = useContext(DataContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [rowsTable, setRowsTable] = useState([]);
  const [variables, setVariables] = useState([]);
  const [filteredHeaders, setFilteredHeaders] = useState([]);
  const [filteredVariables, setFilteredVariables] = useState([]);
  const [filteredRowsTable, setfilteredRowsTable] = useState([]);
  const [uploadData, setUploadData] = useState(null);
  const [models, setModels] = useState([]);

  useEffect(() => {
    loadModels();
  }, []);

  const handleNavigation = (path) => {
    console.log(activeStep > 0);
    navigate(path);
    window.scrollTo(0, 0);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleUploadComplete = (response) => {
    setUploadData(response);
    handleNext();
  };

  const handleDeleteColumns = async () => {
    const body = {
      file_name: name,
      deleted_columns: filteredVariables,
      dataframe: uploadData.data.dataframe,
    };
    try {
      const response = await post("generate_preview_graphics/", body);
      await handleCreateModel(response.data, response.dataframe);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al cargar el archivo.");
      }
    }
  };

  const loadModels = async () => {
    try {
      const response = await get("get_models/" + userId);
      setModels(response.data);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data}`);
        handleNavigation("/dashboard");
      } else {
        toast.error("Error: Ocurrió un error al cargar los modelos.");
        handleNavigation("/dashboard");
      }
    }
  };

  const handleCreateModel = async (data, dataframe) => {
    const body = {
      name: name,
      creation_date: new Date().toISOString().split("T")[0],
      phase: "Visualización de datos",
      prediction: false,
      favorite: false,
      data_view: data,
      original_dataframe: dataframe,
      intersect_dataframe: null,
      metrics_dataframe: null,
      fairness_definition: "",
      detection_tuples: null,
      mitigated_dataframe: null,
      mitigated_metrics_dataframe: null,
      mitigate_variable: null,
      biased_variables: null,
      optimal_threshold: null,
      id_user: userId,
      is_binary: uploadData.data.is_binary,
    };
    try {
      const response = await post("save_model/", body);
      handleSelectModel(response.id_model);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al crear el modelo.");
      }
    }
  };

  const handleSelectModel = async (modelId) => {
    try {
      const response = await get("get_model/" + modelId);
      selectData(response.data);
      loadModels();
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data}`);
      } else {
        toast.error("Error: Ocurrió un error al cargar el modelo.");
      }
    }
  };

  useEffect(() => {
    if (uploadData) {
      const transformedData = Object.keys(uploadData.data.dataframe).map(
        (key) => {
          return {
            header: key,
            values: uploadData.data.dataframe[key],
          };
        }
      );
      setRowsTable(transformedData);
      setVariables(uploadData.data.variables);
      setFilteredHeaders(
        transformedData.map((key) => {
          return key.header;
        })
      );
      setfilteredRowsTable(transformedData);
      setFilteredVariables([]);
    }
  }, [uploadData]);

  useEffect(() => {
    setfilteredRowsTable(() => {
      const updatedFilteredRowsTable = rowsTable.filter((row) =>
        filteredHeaders.includes(row.header)
      );
      return updatedFilteredRowsTable;
    });
  }, [filteredHeaders, rowsTable]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCheckBox = (variable) => {
    if (
      !filteredVariables.includes(variable) &&
      filteredVariables.length === variables.length - 1
    ) {
      toast.warning("No se pueden eliminar todas las columnas");
      return;
    }

    setFilteredVariables((prevFilteredVariables) =>
      prevFilteredVariables.includes(variable)
        ? prevFilteredVariables.filter((v) => v !== variable)
        : [...prevFilteredVariables, variable]
    );

    setFilteredHeaders((prevFilteredHeaders) =>
      prevFilteredHeaders.includes(variable)
        ? prevFilteredHeaders.filter((v) => v !== variable)
        : [...prevFilteredHeaders, variable]
    );
  };

  return (
    <PageContainerDashboard>
      <LoadNameModal
        open={open}
        onClose={onClose}
        namesList={models}
        setName={setName}
        handleDeleteColumns={handleDeleteColumns}
      />
      <Grid
        container
        spacing={3}
        sx={{ p: "20px", alignContent: "flex-start" }}
      >
        <Grid item xs={12}>
          <ShadowCard padding="16px">
            <Accordion
              sx={{
                margin: "0px !important",
                borderRadius: "0px 0px 16px 16px",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <FlexColumn gap="8px">
                  <Typography variant="h5">Formato de Carga</Typography>
                </FlexColumn>
              </AccordionSummary>
              <AccordionDetails>
                <FlexColumn gap="8px">
                  <Typography variant="body1">
                    Aquí tienes una pequeña descripción de cómo debes cargar tus
                    datos para el correcto funcionamiento de la herramienta.
                  </Typography>
                  <StyledDivider sx={{ marginBottom: "20px" }} />
                </FlexColumn>

                <Grid
                  container
                  spacing={6}
                  columns={{ lg: 4, xl: 12, alignContent: "flex-start" }}
                >
                  <Grid item zeroMinWidth lg={4} xl={4}>
                    <FlexColumn gap="8px">
                      <ul style={{ color: "#02403C", margin: "0px" }}>
                        <li>
                          <Typography variant="body1">
                            Carga tus datos en formato .csv o .xlsx
                          </Typography>
                        </li>
                      </ul>
                    </FlexColumn>
                  </Grid>
                  <Grid item zeroMinWidth lg={4} xl={4}>
                    <StyledTable borderaxis="both">
                      <thead>
                        <tr key="Example">
                          <th key="y1">
                            <FlexRow
                              gap="8px"
                              justifycontent="center"
                              alignItems="center"
                            >
                              prediccion <IconInfo />
                            </FlexRow>
                          </th>
                          <th key="y2">
                            <FlexRow
                              gap="8px"
                              justifycontent="center"
                              alignItems="center"
                            >
                              etiqueta real <IconInfo />
                            </FlexRow>
                          </th>
                          <th key="vp1">
                            <FlexRow
                              gap="8px"
                              justifycontent="center"
                              alignItems="center"
                            >
                              vp1 <IconInfo />
                            </FlexRow>
                          </th>
                          <th key="...">
                            <FlexRow
                              gap="8px"
                              justifycontent="center"
                              alignItems="center"
                            >
                              ... <IconInfo />
                            </FlexRow>
                          </th>
                          <th key="vpn">
                            <FlexRow
                              gap="8px"
                              justifycontent="center"
                              alignItems="center"
                            >
                              vpn <IconInfo />
                            </FlexRow>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, index) => (
                          <tr key={index}>
                            <td>{row.y1}</td>
                            <td>{row.y2}</td>
                            <td>{row.vp1}</td>
                            <td>{row.etc}</td>
                            <td>{row.vpn}</td>
                          </tr>
                        ))}
                      </tbody>
                    </StyledTable>
                  </Grid>
                  <Grid item zeroMinWidth lg={4} xl={4}>
                    <FlexColumn gap="8px" alignItems="flex-start">
                      <Typography variant="h6">Variables</Typography>
                      <ul style={{ color: "#02403C", margin: "0px" }}>
                        <li>
                          <Typography variant="body1">
                            prediccion: Es el valor predicho por el modelo
                            supervisado
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1">
                            etiqueta_real: Es el valor binario real que el
                            modelo intenta predecir
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1">
                            vp: Se refiere a las variables protegidas que
                            quieres analizar
                          </Typography>
                        </li>
                      </ul>
                      <Typography variant="body1">
                        ¿Tienes dudas sobre algún concepto? Consulta aquí el
                        glosario
                      </Typography>
                      <StyledButton
                        variant="contained"
                        component="label"
                        onClick={() => handleNavigation("/dashboard/glossary")}
                      >
                        Ir al glosario
                      </StyledButton>
                    </FlexColumn>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </ShadowCard>
        </Grid>
        <Grid item xs={12}>
          <FlexColumn gap="8px">
            <Typography variant="h5">
              Explora gráficamente cómo están distribuidos los datos que
              componen tu modelo.
            </Typography>
            <StyledDivider />
          </FlexColumn>
        </Grid>
        <Grid item xs={12}>
          {activeStep === 0 && (
            <Grid
              container
              spacing={1}
              columns={{ md: 4, lg: 12, alignContent: "flex-start" }}
            >
              <Grid item md={4} lg={4}>
                <BorderCard>
                  <FlexColumn
                    padding="32px"
                    alignItems="center"
                    justifycontent="center"
                  >
                    <Typography variant="h6" textAlign="center">
                      ¡Aún no tienes datos cargados!
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      Que esperas para navegar en el mundo de la inteligencia
                      artificial responsable
                    </Typography>
                  </FlexColumn>
                </BorderCard>
              </Grid>

              <Grid item md={4} lg={8}>
                <BorderCard>
                  <FlexColumn padding="12px" gap="8px" alignItems="flex-start">
                    <Typography variant="h6">Carga de datos</Typography>
                    <StyledStepper
                      variant="dots"
                      steps={3}
                      activeStep={0}
                      position="static"
                      justifycontent="flex-start"
                    />
                    <Typography variant="h4">
                      Paso 1 - Seleccionar archivos
                    </Typography>
                    <Typography variant="body1">
                      Sube tus datos archivo .csv, .xlsx, .xls
                    </Typography>
                    <StyledInputBox
                      onUploadComplete={handleUploadComplete}
                      loading={loading}
                      setLoading={setLoading}
                    />
                    <Typography variant="body1">
                      Tienes dudas de cómo organizar los datos para tener los
                      mejores resultados, <b>ver tutorial</b>
                    </Typography>
                    <StyledInputButton
                      onUploadComplete={handleUploadComplete}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  </FlexColumn>
                </BorderCard>
              </Grid>
            </Grid>
          )}
          {activeStep === 1 && (
            <Grid
              container
              spacing={1}
              columns={{ md: 4, lg: 12, alignContent: "flex-start" }}
            >
              <Grid item md={4} lg={4}>
                <BorderCard>
                  <FlexColumn
                    padding="32px"
                    gap="12px"
                    alignItems="flex-start"
                    justifycontent="flex-start"
                  >
                    <Typography variant="h6">
                      Verifica el estado de tus datos
                    </Typography>
                    <Typography variant="body1">
                      Si la primera fila de tus datos corresponde a los nombres
                      de las columnas, haz click en "Siguiente". De lo
                      contrario, haz click en "Atrás" y carga la versión
                      correcta del archivo.
                    </Typography>
                    <StyledButton
                      variant="contained"
                      component="label"
                      onClick={handleNext}
                    >
                      Siguiente
                    </StyledButton>
                    <StyledButton
                      variant="outlined"
                      component="label"
                      onClick={handleBack}
                      secondary="true"
                    >
                      Atrás
                    </StyledButton>
                  </FlexColumn>
                </BorderCard>
              </Grid>

              <Grid item md={4} lg={8}>
                <BorderCard height="100%">
                  <FlexColumn padding="12px" gap="8px" alignItems="flex-start">
                    <Typography variant="h6">Carga de datos</Typography>
                    <StyledStepper
                      variant="dots"
                      steps={3}
                      activeStep={1}
                      position="static"
                      justifycontent="flex-start"
                    />
                    <Typography variant="h4">
                      Paso 2 - Previsualización de archivos
                    </Typography>
                    <TableContainer sx={{ maxHeight: "260px", padding: "8px" }}>
                      <StyledTable borderaxis="both">
                        <thead>
                          <tr key="Preview">
                            {rowsTable.map((row) => (
                              <th key={row.header}>{row.header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {rowsTable[0]?.values
                            .slice(0, 20)
                            .map((_, rowIndex) => (
                              <tr key={rowIndex}>
                                {rowsTable.map((row) => (
                                  <td key={row.header + rowIndex}>
                                    {typeof row.values[rowIndex] === "number"
                                      ? Number.isInteger(row.values[rowIndex])
                                        ? row.values[rowIndex]
                                        : row.values[rowIndex].toFixed(2)
                                      : row.values[rowIndex]}
                                  </td>
                                ))}
                              </tr>
                            ))}
                        </tbody>
                      </StyledTable>
                    </TableContainer>
                  </FlexColumn>
                </BorderCard>
              </Grid>
            </Grid>
          )}
          {activeStep === 2 && (
            <Grid
              container
              spacing={1}
              columns={{ md: 4, lg: 12, alignContent: "flex-start" }}
            >
              <Grid item md={4} lg={4}>
                <BorderCard height="100%">
                  <FlexColumn
                    height="100%"
                    padding="16px 32px"
                    gap="8px"
                    alignItems="flex-start"
                    justifycontent="flex-start"
                  >
                    <Typography variant="h6">Seleccionar columnas</Typography>
                    <Typography variant="body1">
                      Las variables que selecciones son las que se usarán a lo
                      largo del análisis de tu modelo.
                    </Typography>
                    <StyledDivider />
                    <FormControl component="fieldset" sx={{ width: "100%" }}>
                      <FormGroup
                        aria-label="position"
                        column="true"
                        sx={{
                          width: "100%",
                          maxHeight: "200x",
                          flexWrap: "nowrap",
                          overflow: "auto",
                        }}
                      >
                        {variables.map((variable, index) => (
                          <FlexColumn key={index}>
                            <FormControlLabel
                              value={variable}
                              control={
                                <Checkbox
                                  onChange={() => handleCheckBox(variable)}
                                  checked={filteredHeaders.includes(variable)}
                                  sx={{ marginLeft: "auto" }}
                                />
                              }
                              label={variable}
                              labelPlacement="start"
                              sx={{ margin: "0px" }}
                            />
                            <StyledDivider />
                          </FlexColumn>
                        ))}
                      </FormGroup>
                    </FormControl>
                    <StyledButton
                      variant="contained"
                      component="label"
                      onClick={() => setOpen(true)}
                    >
                      Siguiente
                    </StyledButton>
                    <StyledButton
                      variant="outlined"
                      component="label"
                      onClick={handleBack}
                      secondary="true"
                    >
                      Cancelar
                    </StyledButton>
                  </FlexColumn>
                </BorderCard>
              </Grid>

              <Grid item md={4} lg={8}>
                <BorderCard height="100%">
                  <FlexColumn padding="12px" gap="8px" alignItems="flex-start">
                    <Typography variant="h6">Carga de datos</Typography>
                    <StyledStepper
                      variant="dots"
                      steps={3}
                      activeStep={2}
                      position="static"
                      justifycontent="flex-start"
                    />
                    <Typography variant="h4">
                      Paso 3 - Definir variables
                    </Typography>
                    <TableContainer sx={{ maxHeight: "260px", padding: "8px" }}>
                      <StyledTable borderaxis="both">
                        <thead>
                          <tr key="Filter">
                            {filteredRowsTable.map((row) => (
                              <th key={"F" + row.header}>{row.header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {filteredRowsTable[0]?.values
                            .slice(0, 20)
                            .map((_, rowIndex) => (
                              <tr key={"F" + rowIndex}>
                                {filteredRowsTable.map((row) => (
                                  <td key={"F" + row.header + rowIndex}>
                                    {typeof row.values[rowIndex] === "number"
                                      ? Number.isInteger(row.values[rowIndex])
                                        ? row.values[rowIndex]
                                        : row.values[rowIndex].toFixed(2)
                                      : row.values[rowIndex]}
                                  </td>
                                ))}
                              </tr>
                            ))}
                        </tbody>
                      </StyledTable>
                    </TableContainer>
                  </FlexColumn>
                </BorderCard>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </PageContainerDashboard>
  );
};

export default LoadData;

import { Grid, styled, TextField, Typography } from "@mui/material";
import StyledFooter from "../../components/common/navigation/StyledFooter";
import PageContainer from "../../components/shared/container/PageContainer";
import BorderCard from "../../components/shared/container/BorderCard";
import FlexColumn from "../../components/shared/container/FlexColumn";
import FlexRow from "../../components/shared/container/FlexRow";
import StyledButton from "../../components/common/button/StyledButton";
import MaxWidthContainer from "../../components/shared/container/MaxWidthContainer";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthProvider";
import { useApiClient } from "../../api/apiService";

const CustomTextField = styled(TextField)(({ purple, ...props }) => ({
  color: "#02403C",
  "& .MuiOutlinedInput-root": {
    color: "#02403C",
    borderRadius: "10px",
    "& fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
    },
    "&.Mui-focused fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#02403C",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#02403C",
  },
}));

const Login = () => {
  const navigate = useNavigate();
  const { post } = useApiClient();
  const { setUserId, setUserName } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };
  const handlePasswordChange = (event) => setPassword(event.target.value);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleLogin();
  };

  const handleLogin = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "token/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("access_token", data.access);
        localStorage.setItem("refresh_token", data.refresh);
        getUserId();
      } else {
        toast.error(
          `Error: ${
            data.non_field_errors
              ? data.non_field_errors[0]
              : "Credenciales inválidas"
          }`
        );
      }
    } catch (error) {
      toast.error("Error: Ocurrió un error al iniciar sesión.");
    }
  };

  const getUserId = async () => {
    const body = {
      email: email,
    };
    try {
      const response = await post("get_user_by_email/", body);
      setUserId(response.id_user);
      setUserName(response.name);
      localStorage.setItem("user_id", response.id_user);
      localStorage.setItem("username", response.name);
      handleNavigation("/dashboard");
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al obtener el id del usuario.");
      }
    }
  };

  return (
    <PageContainer>
      <MaxWidthContainer>
        <Grid
          container
          spacing={3}
          sx={{ marginTop: "72px", marginBottom: "72px" }}
          columns={{ xs: 4, sm: 12, xl: 12 }}
        >
          <Grid item xs={4} sm={6} xl={6}>
            <BorderCard shadow>
              <FlexColumn
                padding="28px"
                component="form"
                gap="28px"
                alignItems="flex-start"
                onSubmit={handleSubmit}
              >
                <FlexColumn gap="28px" alignItems="flex-start" width="100%">
                  <Typography variant="h5" color={"#02403C"}>
                    ¡Hola de nuevo!
                  </Typography>
                  <CustomTextField
                    label="Correo"
                    variant="outlined"
                    fullWidth
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    error={!isEmailValid}
                    helperText={!isEmailValid && "Correo electrónico inválido"}
                  />
                  <CustomTextField
                    label="Contraseña"
                    variant="outlined"
                    fullWidth
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </FlexColumn>
                <FlexRow justifyContent="space-between" width="100%">
                  <StyledButton
                    variant="contained"
                    type="submit"
                    disabled={email === "" || password === ""}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        lineHeight: "100%",
                        marginBottom: "4px",
                        fontWeight: "500",
                        color: "#EBFDEA",
                      }}
                      color={"#02403C"}
                    >
                      Ingresar
                    </Typography>
                  </StyledButton>
                  <Typography
                    variant="body1"
                    sx={{
                      lineHeight: "100%",
                      color: "#02403C90",
                      cursor: "pointer",
                    }}
                    onClick={() => handleNavigation("/forgot-password")}
                  >
                    Olvidé mi contraseña
                  </Typography>
                </FlexRow>

                <Typography variant="body1">
                  Aún no tienes una cuenta,{" "}
                  <b
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/register")}
                  >
                    {" "}
                    regístrate aquí
                  </b>
                </Typography>
              </FlexColumn>
            </BorderCard>
          </Grid>
        </Grid>
      </MaxWidthContainer>
      <StyledFooter />
    </PageContainer>
  );
};

export default Login;

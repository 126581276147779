import React from "react";
import { Box, Toolbar } from "@mui/material";
import { styled } from "@mui/system";
import LoadData from "../loadData/LoadData";

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
}));

const PageContainerDashboard = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        minWidth: "1280px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CustomBox {...props}>
        <LoadData />
        {props.children}
      </CustomBox>
    </Box>
  );
};

export default PageContainerDashboard;
